.product-page {
  padding: 7px 50px;
  background-color: var(--background);

  > div:first-child {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    gap: 40px;

    > .button {
      border: none;
    }
  }

  &__empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 80vh;

    > p:nth-child(2) {
      margin-top: -120px;
      font-size: 30px;
      font-weight: 700;
    }

    > p:nth-child(3) {
      font-size: 16px;
      font-weight: 700;
    }

    > .button {
      width: 200px;
      margin-top: 20px;
    }
  }

  &__product-info {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    gap: 30px;

    margin-top: 30px;
    > img {
      max-height: 525px;
      max-width: 30%;
      aspect-ratio: 3/4;
    }

    > div {
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: start;
      gap: 30px;
    }
    table {
      width: 100%;
      border-collapse: collapse;
      tr {
        border-bottom: 1px solid var(--table-border);
        height: 45px;
      }

      td:first-child {
        font-weight: 700;
        color: var(--grey);
      }

      td:last-child {
        padding-left: 50px;

        > a {
          color: var(--link-color);
        }
      }

      td {
        font-size: 14px;
        color: var(--font-color);

        > a {
          color: var(--link-color);

          > span {
            color: var(--black);
          }
        }

        > p {
          margin-bottom: 5px;
        }
      }
    }
  }

  &__category-link {
    color: var(--link-color);

    > span {
      color: var(--black);
    }
  }
  &__header-buttons {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 10px;

    .button {
      aspect-ratio: 1/1;
      min-height: 35px;
    }
  }

  &__name {
    color: var(--font-color);

    font-size: 16px;
    font-weight: 700;
    width: 100%;
    text-align: start;
    margin-left: auto;
    margin-bottom: 25px;
  }

  &__first-row {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 20px;
    width: 100%;
    margin-bottom: 15px;
  }

  &__rating {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;

    > svg {
      height: 25px;
    }

    > span {
      font-size: 14px;
      font-weight: 700;
      color: var(--font-color);
    }
  }

  &__feetback {
    font-size: 14px;
    color: var(--grey);
    margin-left: 20px;
    font-weight: 700;
    color: var(--font-color);
  }

  &__articul {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 5px;

    > svg {
      height: 25px;
    }

    > span {
      font-size: 14px;
      font-weight: 700;
      color: var(--font-color);
    }
  }

  &__date-create {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    gap: 5px;
    color: var(--font-color);

    > span {
      color: var(--grey);
    }
  }

  > div:nth-child(2) {
    display: flex;
    flex-direction: row;
    justify-content: end;
    width: 100%;
    margin-top: 20px;
  }

  &__stat-panel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--table-border);
    border-radius: 10px;
    padding: 25px;
    margin-top: 20px;
  }

  &__stat-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 0 10px;

    > p:nth-child(1) {
      font-size: 18px;
      font-weight: 700;
      text-align: center;
      color: var(--font-color);
    }

    > p:nth-child(2) {
      font-size: 16px;
      font-weight: 600;
      color: var(--grey);
      text-align: center;
      text-overflow: ellipsis;
    }
  }

  &__line-chart {
    width: 100%;
    border: 1px solid var(--table-border);
    border-radius: 10px;
    padding: 25px;
    margin-top: 50px;
    min-height: 380px;
    display: flex;
    > .small-loader {
      margin: auto;
    }
  }

  &__bar-charts {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 50px;

    > div {
      border: 1px solid var(--table-border);
      border-radius: 10px;
      padding: 15px 0 15px 0;

      // aspect-ratio: 4/3;
      // display: flex;
      // flex-direction: column;
      // justify-content: start;
      // align-items: start;
      // gap: 10px;

      > p {
        font-size: 16px;
        font-weight: 600;
        margin-left: 55px;
        margin-bottom: 10px;
        color: var(--font-color);
      }
    }
  }

  &__stocks {
    margin-top: 50px;
    margin-bottom: 20px;
  }

  &__favorite-btn {
    &_isFavorite {
      .button {
        svg {
          fill: var(--light_blue);

          > * {
            stroke: var(--light_blue);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 840px) {
  .product-page {
    padding: 7px 15px;

    > div > .button {
      display: none;
    }

    > div:nth-child(2){
      margin-top: -45px;
    }

    &__name{
      font-size: 16px;
    }

    &__product-info {
      margin-top: 0;
      flex-direction: column;

      >img{
        max-width: 100%;
        border-radius: 10px;
        margin-right: auto;
        margin-left: auto;
       
      }

      >div{
        flex-direction: column;
      }

      table td:first-child {
        width: max-content;
      }
    }

    &__first-row{
      flex-wrap: wrap;
    }

    &__date-create{
      flex-flow: 1;
    }

    &__stat-panel{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      padding: 0;
    }

    &__stat-item{
      border: 1px solid var(--table-border);
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 10px;
      

      &:nth-child(1){
        border-top: none;
        border-left: none;
        border-right: none;

      }

      &:nth-child(2){
        border-top: none;
        border-right: none;
      }
      &:nth-child(3){
        border-top: none;
        border-left: none;
        border-right: none;
      }

      &:nth-child(4){
        border-top: none;
        border-right: none;
      }

      &:nth-child(5){
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: none;

      }
      &:nth-child(6){
        border-top: none;
        border-right: none;
        border-bottom: none;
      }
    }

    &__line-chart{
      margin-top: 20px;
    }

    &__bar-charts{
      margin-top: 20px;
      grid-template-columns: 1fr;
    }

    &__stocks {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}
