.categories-page {
  &__categories {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 15px;
    row-gap: 10px;
    margin-top: 30px;
  }

  &__header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 20px;

    .search-input {
      margin-left: auto;
      margin-bottom: 0;
    }

    .button {
      font-weight: 500;
      max-height: 35px;
      min-height: 35px !important;
      height: 35px;
    }
  }

  &__bar-chart {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  $column-count: 2;
  $colors: (
    #c14b4b,
    #c68c47,
    #ced055,
    #5cb43e,
    #41bca8,
    #478dbf,
    #575cc2,
    #974abb,
    #c14b4b,
    #c68c47,
    #ced055,
    #5cb43e,
    #41bca8,
    #478dbf,
    #575cc2,
    #974abb,
    #c14b4b,
    #c68c47,
    #ced055,
    #5cb43e,
    #41bca8,
    #478dbf,
    #575cc2,
    #974abb
  );
  $colorsLength: length($colors);

  @for $i from 1 through $colorsLength {
    &__categories {
      @for $j from 1 through $column-count {
        @if ($i==$colorsLength) {
          $i: 1;
        }
        > div:nth-child(#{$j+($i - 1)*$column-count}) {
          .category-selected-item__color-block,
          .seacrh-category-selected-item__color-block {
            background-color: nth($colors, $i);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 840px) {
  .categories-page {
    &__header {
      flex-direction: column;

      .search-input {
        width: 100%;
      }
    }

    &__categories {
      grid-template-columns: 1fr;
    }

    $column-count: 1;
    $colors: (
      #c14b4b,
      #c68c47,
      #ced055,
      #5cb43e,
      #41bca8,
      #478dbf,
      #575cc2,
      #974abb,
      #c14b4b,
      #c68c47,
      #ced055,
      #5cb43e,
      #41bca8,
      #478dbf,
      #575cc2,
      #974abb,
      #c14b4b,
      #c68c47,
      #ced055,
      #5cb43e,
      #41bca8,
      #478dbf,
      #575cc2,
      #974abb
    );
    $colorsLength: length($colors);

    @for $i from 1 through $colorsLength {
      &__categories {
        @for $j from 1 through $column-count {
          @if ($i==$colorsLength) {
            $i: 1;
          }
          > div:nth-child(#{$j+($i - 1)*$column-count}) {
            .category-selected-item__color-block,
            .seacrh-category-selected-item__color-block {
              background-color: nth($colors, $i);
            }
          }
        }
      }
    }
  }
}
