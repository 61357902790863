.filter-bar {
  height: 100%;
  box-sizing: border-box;
  > div:first-child {
    padding: 10px 15px;
    //height: 200px;
    overflow-y: auto;
    height: calc(100% - 55px);

    &::-webkit-scrollbar {
      width: 5px;
      height: 7px;

      //background-color: red;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: var(--grey);
    }
    &::-webkit-scrollbar-button {
      display: none;
    }
  }
  > div:last-child {
    padding: 10px 15px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
    z-index: 2;
    background-color: var(--white_1);
    margin-bottom: 0;
    margin-top: auto;
    background-color: transparent;

    border-top: 1px solid var(--table-border);

    .button {
      font-weight: 500;
      min-height: auto !important;
    }
  }
}
