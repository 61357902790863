.favorites-page {
  padding: 7px 50px;
  background-color: var(--background);

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 15px;
    margin: 30px 0 20px 0;
    flex-wrap: wrap;

    > .search-input {
      margin-left: auto;
      margin-right: 0;
    }
  }

  .pagination:last-child {
    margin-top: -150px;
    position: relative;
    z-index: 100;
  }

  #myGrid .ag-cell-value:first-child,
  #myGrid .ag-cell:first-child,
  #myGrid .ag-group-value:first-child {
    overflow: visible !important;
    overflow-y: visible !important;
  }


  #myGrid .ag-center-cols-viewport {
    box-sizing: border-box;
    height: calc(100% + 400px) !important;
    padding-bottom: 150px;
    background-color: var(--background);
  }


}


@media screen and (max-width: 840px){
  .favorites-page{
    padding: 7px 15px;

    &__buttons {
      margin-top: 15px;
    }

    .search-input{
      margin: 0;
      width: 100%;
    }
  }
}