.table-load {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 650px;
  z-index: 1000;
  background: var(--white);

  &__inner {
    animation: load 4s infinite;
  }

  &__left {
    animation: load-left 4s infinite;
    transform: translateX(-1.25rem);
  }

  &__right {
    animation: load-right 4s infinite;
    transform: translateX(1.25rem);
  }

  @keyframes load {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.5) rotate(-180deg);
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes load-left {
    50% {
      transform: translateX(0);
    }
  }

  @keyframes load-right {
    50% {
      transform: translateX(0);
    }
  }
}
