.pie-chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__pie:hover {
    cursor: pointer;
  }

  &__error {
    margin-top: auto;
    margin-bottom: auto;
    color: var(--font-color);
  }
}
