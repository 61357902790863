.line-loading {
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: 200px;
  height: 60px;
  background: var(--popup-background);
  box-shadow: 0px 5.521px 20.703px 0px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  &__line {
    width: 125px;
    height: 10px;
    position: relative;
    overflow: hidden;
    background-color: var(--light_gray_2);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;

    &:before {
      content: '';
      position: absolute;
      left: 0%;
      height: 10px;
      width: 0%;
      background-color: var(--load-line-color);
      -webkit-animation: lineAnim 3s linear infinite;
      -moz-animation: lineAnim 3s linear infinite;
      animation: lineAnim 3s linear infinite;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
    }
  }

  p {
    font-size: 14px;
    color: var(--grey);
  }

  @keyframes lineAnim {
    0% {
      left: 0%;
      width: 0%;
    }
    10% {
      width: 10%;
    }
    50% {
      //   left: 30%;
      width: 50%;
    }
    70% {
      width: 70%;
    }
    100% {
      //   left: 100%;
      width: 100%;
    }
  }
}
