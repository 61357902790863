* {
  appearance: none;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  line-height: 1.32;
}

button {
  background: none;
  cursor: pointer;
}

input,
select {
  border: 0;
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-ms-clear {
  display: none;
}

:focus,
:active {
  outline: none;
}

a {
  color: var(--text_secondary);
  text-decoration: none;
  outline: none;
}

button {
  outline: none;
  border: none;
  cursor: pointer;
}

ul,
ol {
  list-style: none;
}

textarea {
  outline: none;
  border: 0;
  resize: none;
}
