@media screen and (max-width: 840px){
  .registation-page{
    // height: auto !important;
    >div{
      width: 80% !important;
      padding: 30px 0 !important;
    }
    &__form{
      grid-template-columns: 100% !important;
    }

    &__agreement{
      p{
        text-align: start !important;
      }
    }
  }
}

.registation-page {
  box-sizing: border-box;
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: var(--dark_blue);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('../../shared/image/svg/background-login.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &__quickReg{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 15px;

    >p{
      color: var(--white_1);
    }
  }

  &__modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 150px;
    gap: 20px;
    margin-top: -20px;
    > p {
      text-align: center;
    }
  }

  &__error {
    color: red !important;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // width: 40%;
    width: 22%;
    gap: 15px;
    > h1 {
      font-weight: 700;
      font-size: 20px;
      line-height: 130.5%;
      color: var(--white_1);
      flex: none;
      order: 0;
      flex-grow: 0;
      margin: 0 0 15px 0;
    }

    > p {
      font-size: 12px;
      color: var(--white_1);
      text-align: justify;

      > a {
        color: var(--green);
        margin-left: 7px;
      }
    }

    > .button {
      font-weight: 500 !important;
      width: 100%;
      // margin-top: 20px;
    }
  }

  &__form {
    width: 100%;
    display: grid;
    gap: 20px;
    // grid-template-columns: repeat(2, calc(50% - 10px));
    grid-template-columns: repeat(1, 100%);
  }

  &__input {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 15px;
    width: 100%;

    > p {
      font-size: 14px;
      color: var(--white_1);
      font-weight: 300;
    }

    .select {
      &__button {
        height: 50px;
        background-color: var(--dark-input);
        border: 1px solid rgba(37, 186, 123, 0.2);
        color: var(--white_1);
        background-image: url('../../shared/image/svg/arrow-bottom-white.svg');
        overflow: hidden;
        width: 100%;
      }

      .popup {
        background-color: var(--dark-input);
        padding: 0;
        max-height: 180px !important;
      }

      .search-input {
        width: 90%;
        margin: 10px auto;
        color: var(--white_1);

        svg > * {
          stroke: var(--white_1);
        }
      }

      .radio-group {
        max-height: 100%;
        > div {
          color: var(--white_1);
          border-bottom: 1px solid rgba(37, 186, 123, 0.2);

          &:last-child {
            border-bottom: none;
          }
        }

        svg > * {
          fill: var(--white_1);
        }
      }
    }
  }

  &__gender {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 10px;

    > div {
      input[type='radio'] {
        display: none;
      }
      input[type='radio']:checked + label::after {
        display: inline-block;
      }

      label {
        display: inline-block;
        cursor: pointer;
        position: relative;
        padding-left: 30px;
        margin-right: 0;
        line-height: 18px;
        user-select: none;
        font-size: 14px;
        color: var(--white_1);
        height: 21px;

        &:before {
          content: '';
          display: inline-block;
          width: 21px;
          height: 21px;
          border-radius: 50%;
          border: 1px solid var(--green);
          position: absolute;
          left: 0;
          bottom: 1px;
        }

        &::after {
          content: '';
          display: none;
          width: 11px;
          height: 11px;
          border-radius: 50%;
          position: absolute;
          left: 6px;
          bottom: 6px;
          background-color: var(--green);
        }
      }
    }
  }

  &__agreement {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 10px;
    > div {
      width: 21px;
      height: 21px;
      min-width: 21px;
      border-radius: 3px;
      border: 1px solid var(--green);
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      > svg {
        width: 12px;
        height: 12px;
        > * {
          fill: var(--green);
        }
      }

      &:hover {
        cursor: pointer;
      }
    }

    > p {
      font-size: 12px;
      color: var(--white_1);
      text-align: start;

      > a {
        color: var(--green);
      }
    }
  }
}
