.product-filter {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 10px;
  // >.search-input {
  //   margin-bottom: 10px !important;
  // }

  &__isStocksPanel{
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin: 5px 0;
  }

  &__selected {
    color: var(--font-color);

    > span {
      font-size: 14px;
    }

    > .select {
      margin-top: 10px;

      .search-input {
        margin-bottom: 10px;
      }
    }

    .category-selected-item {
      &__button {
        background-color: transparent;
        > div:nth-child(1) {
          display: none;
        }

        > span:nth-child(3) {
          display: none;
        }
      }
    }
  }

  &__category-container {
    overflow-y: auto;
    max-height: inherit;

    &::-webkit-scrollbar {
      width: 3px;
      height: 7px;

      //background-color: red;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: var(--table-scroll-color);
    }
    &::-webkit-scrollbar-button {
      display: none;
    }
  }
}
