.top-menu {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 35px;
  margin-bottom: 30px;
  margin-top: 15px;
  > a {
    font-weight: 700;
    font-size: 20px;
    line-height: 33px;

    color: var(--top-menu-color);
  }

  > a.active {
    color: var(--top-menu-hover-color);
  }

  > a:hover {
    color: var(--top-menu-hover-color);
  }
}

@media screen and (max-width: 840px) {
  .top-menu{
    display: none;
  }
}