.select {
  width: 100%;
  height: auto;
  position: relative;

  //   &:last-child {
  //     margin-bottom: 320px;
  //   }

  &__button {
    width: 100%;
    border: 1px solid var(--input-border);
    border-radius: 5px;
    font-size: 14px;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    height: 35px;
    padding: 0 30px 0 10px;
    align-content: center;
    // background-image: url('../../shared/image/svg/arrow-bottom.svg');
color: var(--font-color);
    background-image: var(--arrow-bottom);
    background-position: right;
    background-position-x: calc(100% - 12px);
    background-repeat: no-repeat;

    &:hover {
      cursor: pointer;
    }
  }

  .popup {
    padding: 15px 10px;
    max-height: 300px !important;

    .check-group {
      height: 100%;
      overflow-y: auto;
    }
  }
}
