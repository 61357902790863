.profile-page {
  padding: 7px 50px;
  background-color: var(--background);
}


@media screen and (max-width: 840px) {
  .profile-page{
  padding: 7px 15px;

  }
}