.favorite-check-group {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  gap: 10px;

  > div {
    justify-content: start;
    gap: 10px;
  }

  &__check-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
    > input {
      font-size: 14px;
      font-weight: 600;
      border-bottom: 1px solid var(--input-border);
    //   padding-left: 5px;
      max-width: 90px;
      background-color: transparent;
      color:var(--font-color);
    }

    > p {
      font-size: 14px;
      font-weight: 600;
      color:var(--font-color);

    //   padding-left: 5px;
    }

    > div {
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;
      max-width: 20px;
      max-height: 20px;

      border: 1px solid var(--input-border);
      border-radius: 5px;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      svg {
        width: 13px;
      }

      svg > * {
        fill: var(--white_1);
      }
    }
  }

  &__active {
    background-color: var(--blue);
  }

  &__red {
    background-color: #ef0b0b;
  }

  &__green {
    background-color: #25ba7b;
  }

  &__blue {
    background-color: #1d75bd;
  }
}


@media screen and (max-width: 840px){
  .favorite-check-group{
    flex-wrap: wrap;

    &__check-item{
      >input{
        width: 100%;
        max-width: 100%;
      }
    }
  }
}