.calendar {
  position: relative;
  font-family: Manrope;

  > .button {
    max-height: 45px;
    overflow: hidden;
  }

  &__container {
    padding: 7px;
  }

  &__header {
    border-bottom: 1px solid var(--table-border);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 5px;
    position: relative;

    > p {
      font-size: 14px;
      font-weight: 600;
      color: var(--font-color);
    }

    > span {
      font-size: 14px;
      color: var(--link-color);
    }

    > .button {
      position: absolute;
      right: 5px;
      top: 3px;
      height: 13px;
      width: 13px;
      min-height: 10px !important;
      border: none;

      > svg {
        width: 13px;
        height: 13px;
      }
    }
  }

  &__footer {
    border-top: 1px solid var(--table-border);
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;

    .button {
      color: var(--black);
      padding: 0;
      font-weight: 500;
      font-size: 12px;
      min-height: 20px !important;
      height: fit-content;
      border: none;
    }
  }

  .react-calendar {
    &__navigation {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid var(--table-border);
      padding: 7px 0;

      &__label {
        order: 1;
        flex-grow: 0 !important;
        color: var(--font-color);
      }

      &__prev-button {
        order: 2;
        margin-left: auto;
        margin-right: 15px;
      }

      &__next-button {
        order: 3;
      }

      &__prev-button > svg,
      &__next-button > svg {
        width: 7px;
      }

      svg > * {
        stroke: var(--font-color);
      }
    }

    &__month-view__weekdays {
      padding: 7px 0;

      &__weekday {
        display: flex;
        align-items: center;
        justify-content: center;

        line-height: normal;
        letter-spacing: 0.144px;

        abbr {
          text-decoration: none;
          font-size: 12px;
          font-weight: 700;
          color: var(--font-color);

          &::first-letter {
            text-transform: uppercase;
          }
        }
      }
    }

    &__month-view__days__day {
      color: var(--font-color);
      &--weekend {
        color: var(--red);
      }

      &--neighboringMonth {
        // color: var(--text_menu_color);
        color: var(--font-color);
      }

      &:disabled {
        color: var(--calendar-disable-color);
        // color: red !important;
      }
    }

    &__tile {
      padding: 2px;

      abbr {
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 200ms;
        font-size: 12px;
        // color: var(--font-color);
      }

      &--rangeStart,
      &--rangeEnd {
        abbr {
          aspect-ratio: 1/1;
          background-color: var(--link-color);
          color: var(--white_1);
        }
      }
      &--active {
        background-color: var(--calendar-active-date) !important;
      }
    }

    &__year-view__months__month {
      > abbr {
        width: 33px;
        height: 33px;
        font-size: 12px;
        color: var(--font-color);
      }
    }

    &__decade-view__years > button {
      color: var(--font-color);
    }

    &__century-view__decades__decade {
      color: var(--font-color);
    }
  }
}
