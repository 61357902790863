.popup{
    position: absolute;
    left: 0;
    top: calc(100% + 10px);
    width: 100%;
    flex-shrink: 0;
    border-radius: 5px;
    background: var(--popup-background);
    box-shadow: 0px 5.521px 20.703px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: start;
    z-index: 10;

    >*{

        width: 100%;
        height: 100%;
    }
}