.lineChart {
  display: flex;
  width: inherit;
  height: inherit;

  &__error {
    margin-top: auto;
    margin-bottom: auto;
    color: var(--font-color);

  }
}
