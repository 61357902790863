.favorite-table-item {
  box-sizing: border-box;
  padding: 0 15px;
  min-height: 55px;
  height: 55px;
  font-weight: 500;
  align-items: start;
  overflow-y: hidden;

  > div {
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 10px;
    height: 100%;
    overflow-x: hidden;
  }

  a {
    color: var(--link-color);
    font-size: 14px;
  }

  .button {
    width: 20px !important;
    height: 20px !important;
    min-height: 20px !important;
    min-width: 20px !important;
  }

  &__red > .button {
    background-color: #ef0b0b;
    border: none !important;
  }

  &__green > .button {
    background-color: #25ba7b;
    border: none !important;
  }

  &__blue > .button {
    background-color: #1d75bd;
    border: none !important;
  }

  .popup {
    top: 45px !important;
    left: 30px;
    width: 250px;
  }
}
