.promotion-page {
  padding: 7px 50px;

  background-color: var(--background);
  // height: 100%;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    > div {
      width: fit-content;
      display: flex;
      flex-direction: row;
      justify-content: end;
      align-items: center;
      gap: 15px;

      .select {
        width: 200px;
      }
      .select__button {
        height: 45px !important;
      }
    }
  }

  &__dashboard {
    padding-bottom: 30px;
    width: 100%;
    // height: 75vh;
    // height: 100%;
    margin-top: 20px;
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 0.1fr);
    background-color: var(--background);
  }

  &__chart-stat-row {
    width: 100%;
    height: 100%;
    border: 1px solid var(--table-border);
    border-radius: 10px;
    padding: 25px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    grid-column-start: 1;
    grid-column-end: 4;

    > div {
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      gap: 20px;

      > p {
        font-size: 14px;
        color:var(--font-color);

        &:last-child {
          color: var(--green);
        }
      }
    }
  }
  &__chart-card {
    width: 100%;
    height: 100%;
    // min-height: 300px;
    // max-height: 100%;
    border: 1px solid var(--table-border);
    border-radius: 10px;
    padding: 25px 20px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;

    > p {
      text-align: start;
      width: 100%;
      font-weight: 600;
      color: var(--font-color);
      margin-bottom: 10px;
    }

    > *:last-child {
      margin-top: auto;
      margin-bottom: auto;
      width: 100%;
    }

    > .pie-chart {
      width: 100%;
    }

    &:last-child {
      grid-column-start: 1;
      grid-column-end: 4;
    }
  }

  &__calendar-container {
    &_isShow {
      position: relative;
      &:hover::after {
        content: attr(data-title);
        position: absolute;
        right: calc(100% + 10px);
        top: -15px;
        z-index: 1;
        width: 270px;
        padding: 10px;
        height: 44px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: justify;
        align-items: center;
        background-color: var(--popup-background);
        border-radius: 3px;
        box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
        font-size: 14px;
        color: var(--grey);
      }
    }
  }

  &__help {
    position: relative;

    &:hover::after {
      content: attr(data-title);
      position: absolute;
      right: calc(100% + 10px);
      top: -15px;
      z-index: 1;
      width: 270px;
      padding: 10px;
      height: 44px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      text-align: justify;
      align-items: center;
      background-color: var(--popup-background);
      border-radius: 3px;
      box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
      font-size: 14px;
      color: var(--grey);
    }

    .button {
      border-radius: 0px 5px 5px 0;
      min-height: 35px !important;
      max-height: 35px !important;
      height: 35px;
      font-weight: 500 !important;
      border: none;
      > svg {
        transition: stroke 1s ease-in-out;
        width: 30px;
        height: 30px;
      }
    }

    &_isRed {
      .button {
        > svg {
          transition: stroke 1s ease-in-out;
          stroke: red;
        }
      }
    }
  }
}

@media screen and (max-width: 840px) {
  .promotion-page {
    padding: 7px 15px;

    &__header {
      flex-direction: column;

      > div {
        margin-top: 10px;
        width: 100%;
        flex-wrap: wrap;
        .select {
          width: 100%;
        }
        .select__button {
          height: 45px !important;
        }
      }
    }

    &__calendar-container {
      &_isShow {
        position: relative;
        &:hover::after {
          content: attr(data-title);
          position: absolute;
          left: -60px;
          top: -15px;
          z-index: 1;
          width: 270px;
          padding: 10px;
          height: 44px;
        }
      }
    }

    &__dashboard {
      grid-template-columns: 1fr;
      grid-template-rows: auto;
    }

    &__chart-stat-row {
      grid-column-start: 1;
      grid-column-end: 1;
      flex-direction: column;
      padding: 0;

      > div {
        border-top: 1px solid var(--table-border);
        padding: 10px;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        > p {
          text-align: center;
        }

        &:first-child {
          border-top: none;
        }
      }
    }

    .promotion-page__chart-card:last-child {
      grid-column-start: 1;
      grid-column-end: 1;
    }
  }
}
