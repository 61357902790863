header {
  width: 100%;
  height: 85px;
  border-bottom: 1px solid var(--table-border);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;

  > div {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
  }

  > div:nth-child(1) {
    width: 100%;
    .search-input {
      border: none;
      height: 55px;
      width: 100%;
      gap: 15px;
      padding: 0;

      > input {
        width: 100%;
        font-weight: 500;
        font-size: 16px;
        line-height: 33px;
        color: var(--search-input-font-color);
      }

      > svg {
        stroke: var(--grey);
        width: 27px;
      }
    }
  }

  > div:nth-child(2) {
    gap: 13px;

    > a > svg {
      width: 20px;
      height: 30px;
    }

    > a:last-child {
      > svg,
      > img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }
  }
}

@media screen and (max-width: 840px) {
  header {
    border-bottom: none;
    padding: 0 15px;

    > div:nth-child(1) {
      width: 100%;
      .search-input {
        border: 1px solid var(--input-border);
        height: 35px;
        width: 100%;
        gap: 15px;
        padding: 5px 15px;
      }
    }

    > div:nth-child(2) {
      display: none;
    }
  }
}
