.root-page {
  width: 100%;
  height: 100%;

  > div:nth-child(2) {
    width: calc(100% - 75px);
    margin-left: auto;
    margin-right: 0;
    height: 100vh;
    background-color: var(--background);
  }
}

@media screen and (max-width: 840px) {
  .root-page {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;

    > div:nth-child(2) {
      width: 100%;
    }
  }
}
