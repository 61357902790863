.pagination {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  gap: 15px;
  font-size: 14px;
  color: var(--font-color);
  //margin-bottom: 10px;

  > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 7px;

    > div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    > div:hover {
      cursor: pointer;

      svg > * {
        stroke: var(--blue);
      }
    }

    svg {
      width: 7px;
    }

    svg > * {
      stroke: var(--grey);
    }
  }
}
