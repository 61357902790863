.table {
  &_isSimple {
    .ag-header-cell:hover {
      cursor: auto !important;
    }
  }
}

#myGrid {
  --ag-foreground-color: var(--table-text);
  --ag-background-color: var(--transporate) !important;
  --ag-odd-row-background-color: var(--transporate) !important;
  --ag-row-hover-color: var(--transporate);
  --ag-row-height: 55px;
  --ag-tooltip-background-color: var(--tooltip-backg);
  --ag-header-foreground-color: var(--header-table);
  --ag-header-background-color: var(--header-background-color);
  --ag-odd-row-background-color: rgb(0, 0, 0, 0.03);
  --ag-header-column-resize-handle-color: var(--table-border);

  --ag-font-size: 14px;
  --ag-font-family: manrope;

  --ag-header-column-separator-display: block;
  --ag-header-column-separator-height: 100%;
  --ag-header-column-separator-width: 1px;
  --ag-header-column-separator-color: var(--table-border);

  --ag-header-column-resize-handle-display: none;

  --ag-borders: none;
  --ag-row-border-style: solid;
  --ag-row-border-width: 1px;
  --ag-row-border-color: var(--table-border);

  --ag-range-selection-border-style: none;
  margin-bottom: 20px;

  .ag-tooltip {
    font-weight: 300 !important;
    background-color: var(--tooltip-background);
    color: var(--font-color);
    font-size: 14px;
    font-style: normal;
    font-family: Manrope;
  }

  .ag-header-cell-label {
    justify-content: center !important;
  }

  .ag-header-cell:hover {
    cursor: grab;
  }

  .ag-body-horizontal-scroll {
    order: -2 !important;
  }

  .ag-header {
    margin-top: 10px !important;
    // top: 0px;
    // z-index: 5;
    // align-self: flex-start !important;
    // position: sticky !important;
  }

  // .ag-root {
  //   display: unset !important;
  // }

  // .ag-root-wrapper-body {
  //   display: unset !important;
  // }
  // .ag-root-wrapper {
  //   display: unset !important;
  //   position: relative;
  // }

  .ag-header-cell-text {
    white-space: pre;
  }

  .ag-root-wrapper-body {
    position: relative !important;
  }

  // .ag-body-horizontal-scroll {
  //   position: absolute;
  //   top: -25px;
  //   left: 0;
  // }

  // .ag-popup{
  //   position: absolute;
  //   left: 0;
  //   right: 0;
  // }

  .ag-body-horizontal-scroll-viewport {
    &::-webkit-scrollbar {
      width: 3px;
      height: 7px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: var(--table-scroll-color);
    }
    &::-webkit-scrollbar-button {
      display: none;
    }
  }

  .ag-cell {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    > a {
      color: var(--link-color);
      // display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      gap: 10px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      //vertical-align: middle;

      > img {
        width: 25px;
        height: 35px;
        //margin-right: 10px;
      }
    }
  }
}
