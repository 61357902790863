.table-item {
  text-align: start;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  position: relative;



  > a {
    color: var(--link-color);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    > img {
      margin-right: 10px;
    }

    > span {
      color: var(--black);
    }
  }

  &__product-name {
    display: flex !important;
    flex-direction: row;

    > span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &__category-item {
    display: flex !important;
    flex-direction: row !important;
    justify-content: start;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-height: 45px;

    p {
      width: fit-content;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      // background-color: red;
    }

    a {
      color: var(--link-color);
    }

    span {
      color: var(--black);
    }
  }
}
