.brand-page {
  padding: 7px 50px;
  background-color: var(--background);

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 50px;
    margin-top: 30px;
    margin-bottom: 20px;

    .calendar {
      margin-left: auto;
      margin-right: 0;
    }

   >.button{
    border: none;;
   }
  }

  &__checkbox {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 10px;
    // margin-top: 15px;

    > span {
      font-size: 14px;
      color:var(--font-color);
    }
  }

  &__charts {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    margin-bottom: 30px;

    > .stat-card {
      flex-grow: 1;
      padding: 25px 15px;
      width: fit-content;
      > div {
        width: fit-content;
      }
      svg {
        width: 75px !important;
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 15px;
    margin: 30px 0 0 0;
    flex-wrap: wrap;

    > .pagination {
      margin-left: auto;
      margin-right: 0;
    }
  }
}


@media screen and (max-width: 840px) {
  .brand-page{
    padding: 7px 15px;

    &__header {
      margin-top: 0;
      flex-direction: column;
      height: auto;

      > .button {
        display: none;
      }

      > .calendar {
        position: relative;
      }
    }

    &__info-stats {
      flex-direction: column;
    }

    &__info-seller {
      width: 100%;
    }

    &__charts{
      grid-template-columns: 1fr;
      width: 100%;

    }
  }
}