.sidebarItem {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  height: fit-content;
  cursor: pointer;
  border-top: 0.5px solid var(--aside-bottom-line);
  padding: 11px 25px;
  color: var(--aside-font-color);

  &:nth-child(6) {
    display: none;
  }

  > div:first-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    > div:first-child > svg {
      width: 25px;
    }
  }

  &__subcategories {
    display: none;
    flex-direction: column;
    align-items: start;
    margin-left: 45px;

    div {
      text-decoration: none;
      color: var(--aside-font-color);
      margin: 6px 0;
    }

    div:hover {
      color: var(--aside-font-color-hover);
      font-weight: 700;
    }
  }

  &:hover {
    background-color: var(--aside-background-hover);
    color: var(--aside-font-color-hover);
  }
  & svg * {
    stroke: var(--aside-font-color);
  }

  &:hover svg * {
    stroke: var(--aside-font-color-hover);
  }

  &__title {
    margin-left: 16px;
    display: none;
    transition: 200ms;
    font-weight: 700;
    text-wrap: nowrap;
  }

  &__arrow {
    display: none;
    transition: 200ms;
    margin-left: auto;
    margin-right: 0;
  }
}

@media screen and (max-width: 840px) {
  .sidebarItem {
    &:nth-child(6) {
      display: flex;
    }
  }
}
