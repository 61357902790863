.seller-page {
  padding: 7px 50px;
  background-color: var(--background);

  &__checkbox {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 10px;
    // margin-top: 15px;

    > span {
      font-size: 14px;
      color: var(--font-color);
    }
  }
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 50px;
    margin-top: 30px;
    margin-bottom: 20px;

    .calendar {
      margin-left: auto;
      margin-right: 0;
    }

    > .button {
      border: none;
    }
  }

  &__info-stats {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
  }

  &__info-seller {
    background-color: var(--seller-info-card-background);
    border-radius: 10px;
    height: 247px;
    font-size: 14px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 15px;
    padding: 20px;
    flex-grow: 1;

    > p {
      font-weight: 700;
      color: var(--table-text);

      > span {
        font-weight: 500;
      }
    }

    > a {
      text-decoration: none;
      color: var(--link-color);
    }
  }

  &__charts {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(2, auto);
    gap: 15px;
    // flex-direction: row;
    // justify-content: space-between;
    // flex-wrap: wrap;
    // gap: 15px;
    // align-items: center;
    //margin-bottom: 30px;

    > .stat-card {
      width: 100%;
      justify-content: start;

      //flex-grow: 1;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 15px;
    margin: 20px 0 20px 0;
    flex-wrap: wrap;

    > .pagination {
      margin-left: auto;
      margin-right: 0;
    }
  }
}

@media screen and (max-width: 840px) {
  .seller-page {
    padding: 7px 15px;

    &__header {
      margin-top: 0;

      flex-direction: column;
      height: auto;

      > .button {
        display: none;
      }

      > .calendar {
        position: relative;
      }
    }

    &__info-stats {
      flex-direction: column;
    }

    &__info-seller {
      width: 100%;
    }

    &__charts{
      grid-template-columns: 1fr;
      width: 100%;

    }
  }
}
