.help-page {
  padding: 7px 50px;
  background-color: var(--background);
  

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__contacts {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 10px;

    p {
      font-size: 14px;
      font-weight: 700;
      color: var(--font-color);
    }

    a {
      width: 30px;
      height: 30px;
    }
  }

  &__help-items {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
  }

  &__help-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    background-color: var(--help-item-background);
    border-radius: 10px;
    box-sizing: border-box;
    padding: 20px;
    max-height: 65px;
    overflow-y: hidden;
    transition: all 1s;
    -webkit-transition: 1s;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      gap: 20px;

      > p:nth-child(1) {
        font-size: 16px;
        font-weight: 700;
        color: var(--font-color);
      }

      > p:nth-child(2) {
        font-size: 14px;
        margin-top: 5px;
        color: var(--font-color);
      }

      a {
        color: var(--link-color);
        width: 30px;
        max-width: 30px !important;
        min-width: 30px !important;
        max-height: 30px !important;
        min-height: 30px !important;
      }
    }

    .button {
      width: 30px;
      max-width: 30px !important;
      min-width: 30px !important;
      max-height: 30px !important;
      min-height: 30px !important;
      height: 30px !important;
      border-radius: 50% !important;

      > svg {
        transform: rotate(45deg);
        > * {
          width: 15px;
          height: 15px;
          stroke: var(--white_2);
        }
      }
    }

    &_isActive {
      max-height: fit-content;

      .button > svg {
        transform: rotate(0deg);
      }
    }
  }
}

@media screen and (max-width: 840px){
  .help-page{
    padding: 7px 15px;
  }
}