.h1 {
  font-size: 24px;
  font-weight: 700;
  line-height: 140%;
  color: var(--font-color);
}

.h2 {
  font-size: 22px;
  font-weight: 700;
  line-height: 33px;
  color: var(--font-color);
}
h2 {
  font-size: 25px;
  font-weight: 700;
  line-height: 33px;
}

.h3 {
  font-size: 21px;
  font-weight: 500;
  line-height: 33px;
}

.h4 {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  line-height: 33px;
  color: var(--font-color);
}

.h5 {
  font-size: 12px;
  font-weight: 500;
  line-height: 120%;
}
