.dinamic-line-chart {
  width: 100%;

  &__checkbox-panel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;

    > p {
      font-size: 16px;
      font-weight: 700;
      margin-left: 65px;
      color: var(--font-color);
    }

    .check-group {
      flex-direction: row;
      color: var(--font-color);

      .check-group__check-item > div {
        border-color: var(--checkbox-border2);
      }
      .check-group__active {
        border: none;
      }
    }

    $colors: (#dc48ac, #25ba7b, #5195bf, #974abb, #ebb254);
    $colorsLength: length($colors);

    @for $i from 1 through $colorsLength {
      .check-group__check-item:nth-child(#{$i}) .check-group__active {
        background-color: nth($colors, $i);
      }
    }
  }

  &__custom-tooltip {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 5px;
    font-size: 12px;
    background-color: var(--popup-background);
    box-sizing: border-box;
    border-radius: 3px;
    padding: 3px 5px;

    > div {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 5px;
      justify-content: start;
      align-items: center;

      > div:nth-child(1) {
        color: var(--grey);
      }
    }
  }
}


@media screen and (max-width: 840px) {
  .dinamic-line-chart{
    &__checkbox-panel{
      flex-direction: column;
      gap: 15px;

      p{
        margin: 0;
      }

      .check-group{
        column-gap: 25px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        
      }
    }
  }
}