.recovery-password-page {
  box-sizing: border-box;
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: var(--dark_blue);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('../../shared/image/svg/background-login.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &__error{
    font-size: 14px;
    color:red !important;
  }

  &__modal {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 15px;
    min-height: 230px;

    > p {
      color: var(--white_1);

      &:nth-child(2) {
        font-size: 20px;
      }

      &:nth-child(3) {
        font-size: 16px;
      }
    }

    .button{
      min-width: 130px;
    }
  }

  > div {
    height: 100%;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;

    > h1 {
      font-weight: 700;
      font-size: 20px;
      line-height: 130.5%;
      color: var(--white_1);
      flex: none;
      order: 0;
      flex-grow: 0;
      // margin: 0 0 15px 0;
    }

    > p {
      color: var(--grey);
      font-size: 14px;
      text-align: center;
    }
  }

  >div>.button {
    width: 100% !important;
    font-weight: 500 !important;
    // margin-top: 40px;
  }

  &__input {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 15px;
    width: 100%;
    // margin-top: 40px;

    > p {
      font-size: 14px;
      color: var(--white_1);
      font-weight: 300;
    }
  }

  &__double-send {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;

    > span {
      color: var(--white_1);
      font-size: 14px;
      font-weight: 400;
    }

    > a {
      color: var(--grey);
      font-size: 14px;
    }

    .active {
      color: var(--green);
      text-decoration: underline;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
