.profile-tariffs-page {
  width: 100%;

  > p:nth-child(3) {
    margin-bottom: 20px;
  }
  &__current-tariff {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 20px;

    > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      row-gap: 15px;
      column-gap: 25px;

      > p {
        font-size: 16px;
      }
    }

    .button {
      font-weight: 500 !important;
    }
  }

  &__title {
    color: var(--grey);
  }

  &__value {
    color: var(--font-color);
    &_isActive {
      &_red {
        color: var(--red);
      }
      &_green {
        color: var(--green);
      }
    }
  }

  &__tariff-opt {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    width: 100%;
    gap: 15px;

    > div {
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      gap: 10px;

      > p {
        font-size: 14px;
        color: var(--font-color);
      }

      > svg > * {
        fill: var(--green);
      }
    }
  }

  &__check-tariff {
    .button {
      width: 100%;
      margin-bottom: 20px;
    }

    > p {
      font-size: 20px;
      font-weight: 700;
      width: 100%;
      text-align: center;
      margin: 20px 0;
      color: var(--font-color);
    }
  }
  .modal {
    top: 30%;
    left: 35%;
    height: 400px;
    width: 400px;

    .select {
      .popup {
        padding: 0;
      }
    }
  }
}


@media screen and (max-width: 840px) {
  .profile-tariffs-page{
    &__current-tariff{
      flex-direction: column;
      gap: 20px;

      .button{
        width: 100%;
      }
    }
  }
}