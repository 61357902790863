.wrapper-simple-table {
  width: 100%;
  overflow-y: auto;
  transform: rotateX(180deg);
  padding-bottom: 5px;

  &::-webkit-scrollbar {
    width: 5px;
    height: 7px;

    //background-color: red;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: var(--grey);
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
}

.simple-table {
  transform: rotateX(180deg);
  width: 100%;
  border-collapse: collapse !important;
  border-spacing: 0px;
  margin-bottom: 5px;

  //   display: block;
  //   overflow-x: auto;
  //   overflow-y: visible;
  //   white-space: nowrap;

  tbody {
    width: 100%;
  }

  thead {
    width: 100%;
  }

  th {
    background-color: var(--header-background-color);
    min-height: 50px;
    height: 50px;
    font-weight: 500;
    font-size: 14px;
    color: var(--header-table);
    box-sizing: border-box;
    padding: 0 15px;
    min-width: 130px;
    border-right: 1px solid var(--table-border);
    border-bottom: 1px solid var(--table-border);
  }

  th:last-child {
    border-right: none;
  }

  td {
    box-sizing: border-box;
    padding: 0 15px;
    min-height: 55px;
    height: 55px;
    font-weight: 500;
    font-size: 14px;
    border-bottom: 1px solid var(--table-border);
    text-align: center;
    color: var(--table-text);

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 330px;

    > a {
      display: block;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: start;
      color: var(--link-color);
    }
    > .button {
      margin-left: auto;
      margin-right: auto;
      border: none;
    }
  }
}
