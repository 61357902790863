.profile-keys-page {
  position: relative;
  &__combo-input {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin: 20px 0;
    width: 100%;

    input {
      box-sizing: border-box;
      border: 1px solid var(--input-border);
      height: 35px;
      padding: 5px 15px;
      border-radius: 5px;
      background-color: transparent;
      color: var(--font-color);

      &:nth-child(1) {
        border-radius: 5px 0 0 5px;
        width: 20%;
      }

      &:nth-child(2) {
        border-radius: 0;
        width: 40%;
      }
    }

    > div {
      margin-left: 10px;
      position: relative;

      &:hover::after {
        content: attr(data-title);
        position: absolute;
        left: calc(100% + 10px);
        top: -30px;
        z-index: 1;
        width: 270px;
        padding: 0 5px;
        height: 80px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: justify;
        align-items: center;
        background-color: var(--popup-background);
        border-radius: 3px;
        box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
        font-size: 14px;
        color: var(--grey);
      }
    }

    .button {
      border-radius: 0px 5px 5px 0;
      min-height: 35px !important;
      max-height: 35px !important;
      height: 35px;
      font-weight: 500 !important;
      border: none;
      > svg {
        width: 30px;
        height: 30px;
      }
    }
  }
  &__error {
    font-size: 14px;
    color: var(--red);
    margin-bottom: 20px;
  }

  &__modal-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
    height: 110px;

    .button {
      font-weight: 500 !important;
      width: 120px;
    }
  }

  .modal {
    width: 450px;
  }

  .simple-table {
    margin-top: 20px;
  }
}

@media screen and (max-width: 840px) {
  .profile-keys-page {
    >div:last-child{
      margin-top: 20px;
    }
    &__combo-input {
      flex-direction: column;
      gap: 10px;

     
      input {
        &:nth-child(1) {
          border-radius: 5px;
          width: 100%;
        }

        &:nth-child(2) {
          border-radius: 5px;

          width: 100%;
        }
      }

      .button {
        border-radius: 5px;
        width: 100%;
      }

      > div {
        
        position: absolute;
        top: 0;
        right: 0;

        &:hover::after {
          content: '';
          width: 0px;
          height: 0;
        }
      }
    }
  }
}
