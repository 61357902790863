:root {
  --white_1: #ffffff;
  --white_2: #f5f5f5;
  --black: #222222;
  --orange: hsl(21, 100%, 62%);
  --light_gray_1: #dedede;
  --light_gray_2: #bcbcbc;
  --grey: #7b7983;
  --grey_opacity_5: rgba(123, 121, 131, 0.5);
  --light_blue: #1d75bd;
  --blue: #272264;
  --dark_blue: #121628;
  --red: #ef0b0b;
  --green: #25ba7b;
  --violet: #202039;
  --light-violet: #8a87a8;
  --coral: #ff4343;
  --dark-blue2: #030614;
  --transporate: transparent;

  --revie-text: #c8c8c8;

  --category-red: #c14b4b;
  --category-orange: #c68c47;

  --dark-input: #191e33;

  --background: var(--white_1);
  --aside-background-hover: var(--light_gray_1);
  --aside-background: var(--white_1);
  --side-panel-header: var(--white_2);
  --font-color: var(--black);
  --aside-font-color: var(--black);
  --aside-font-color-hover: var(--blue);
  --aside-bottom-line: var(--grey_opacity_5);
  --border-line: var(--light_gray_1);
  --aside-toogle-icon: var(--dark_blue);
  --aside-logo-part2: var(--light_blue);
  --check-mark-color: var(--green);
  --top-menu-color: var(--grey);
  --icons-grey: var(--grey);
  --top-menu-hover-color: var(--blue);
  --top-menu-sublink-hover: var(--white_2);
  --popup-background: var(--white_1);
  --calendar-accent: var(--light_blue);
  --button-hover-blue: var(--light_blue);
  --link: var(--lightn_blue);
  --text-status: var(--orange);

  --tooltip-background: var(--white_2);
  --tooltip-font-color: var(--black);
  --header-background-color: var(--white_2);
  --link-color: var(--light_blue);
  --table-text: var(--black);

  --icon-color: var(--black);

  --stat-card--type-grey-background: var(--white_2);
  --stat-card-digit-color: var(--black);
  --button-icon-text: var(--dark-grey);
  --profile-icon-circle: var(--white_2);
  --header-icon-stroke: var(--black);
  --header-table: var(--grey);
  --table-border: var(--light_gray_1);
  --seller-info-card-background: var(--white_2);
  --check-mark-color: var(--white_2);

  --input-border: var(--light_gray_1);

  --category-selected-item-background: var(--white_2);
  --table-scroll-color: var(--light_gray_1);

  --calendar-active-date: #deebf5;
  --search-input-font-color: var(--grey);
  --bar-chart-y-axis-label: var(--black);
  --check-box-background: var(--blue);
  --checkbox-border: var(--blue);
  --checkbox-border2: var(--black);

  --load-line-color: var(--violet);
  --blue-btn-back: var(--blue);
  --blue-btn-back-disable: rgba(39, 34, 100, 0.6);
  --warehouse-table-colorful-item: rgb(226, 240, 234);

  --arrow-bottom: url('../image/svg/arrow-bottom.svg');
  --calendar-disable-color: light-dark(rgba(16, 16, 16, 0.3), rgba(255, 255, 255, 0.3));
  --modal-backgroung: var(--white_1);
  --modal-border: none;
  --modal-header: var(--white_2);
  --help-item-background: var(--white_2);

  --toogle-color2: var(--dark-blue2);
  --toogle-color1: var(--white_2);
  --dot-color: var(--white_1);
  --icon-color-white: var(--white_1);
  --blue-btn-font: var(--white_1);
  --theme-toogle-back-color: var(--white_1);

  --category-disable-section: rgba(245, 245, 245, 0.72);

  --promo-step-background: var(--white_2);
  --promo-image-background: var(--white_1);
  --promo-step-back-disable: rgba(245, 245, 245, 0.5);
  body._dark {
    --category-disable-section: rgba(0, 0, 55, 0.72);
    --promo-step-background: var(--dark_blue);
    --promo-image-background: var(--dark-input);
    --promo-step-back-disable: rgba(42, 51, 88, 0.5);
    --toogle-color2: #a3a9ad;
    --dot-color: #a3a9ad;
    --theme-toogle-back-color: #a3a9ad;
    --icon-color-white: #a3a9ad;
    --toogle-color1: var(--dark-blue2);
    --load-line-color: var(--green);
    --aside-background: var(--dark_blue);
    --aside-font-color: #a3a9ad;
    --aside-bottom-line: var(--grey_opacity_5);
    --aside-font-color-hover: var(--green);
    --aside-background-hover: var(--violet);
    --aside-toogle-icon: #a3a9ad;
    --aside-logo-part2: var(--white_1);
    --side-panel-header: var(--violet);
    --arrow-bottom: url('../image/svg/arrow-bottom-white.svg');
    --background: var(--dark-blue2);
    --top-menu-hover-color: var(--green);
    --tooltip-background: var(--dark_blue);
    --tooltip-font-color: var(--white_1);
    --bar-chart-y-axis-label: #a3a9ad;
    --table-scroll-color: var(--grey);
    --stat-card--type-grey-background: var(--dark_blue);
    --stat-card-digit-color: #a3a9ad;
    --header-background-color: var(--dark_blue);
    --table-border: var(--grey);
    --popup-background: var(--violet);
    --button-icon-text: #a3a9ad;
    --profile-icon-circle: var(--dark_blue);
    --header-icon-stroke: var(--white_1);
    --font-color: #a3a9ad;
    --search-input-font-color: var(--light_gray_1);
    --icon-color: #a3a9ad;
    --input-border: var(--grey); // --input-background:
    --link-color: var(--green);
    --table-text: #a3a9ad;
    --seller-info-card-background: var(--dark_blue);
    --category-selected-item-background: var(--dark_blue);
    --check-box-background: var(--green);
    --checkbox-border: #a3a9ad;
    --checkbox-border2: #a3a9ad;
    --blue-btn-back: var(--green);
    --blue-btn-font: #a3a9ad;
    --calendar-active-date: rgba(44, 39, 106, 0.5);
    --blue-btn-back-disable: rgba(37, 186, 123, 0.3);
    --calendar-disable-color: var(--grey_opacity_5);
    --modal-backgroung: var(--dark-input);
    --modal-header: var(--dark_blue);
    --modal-border: 1px solid var(--green);
    --help-item-background: var(--violet);
    --warehouse-table-colorful-item: rgb(0, 1, 40);
  }
}
