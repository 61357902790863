.category-selected-item {
  position: relative;

  &__button {
    &_isArrow {
      background-image: var(--arrow-bottom);
      background-position: right;
      background-position-x: calc(100% - 12px);
      background-repeat: no-repeat;
     
    }
    width: 100%;
    border-radius: 7px;
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    height: 35px;
    padding: 0 30px 0 0px;
    align-content: center;
    background-color: var(--category-selected-item-background);
    display: flex;
    flex-direction: row;
    justify-content: start;
    gap: 10px;
    align-items: center;
    color: var(--font-color);
    &:hover {
      cursor: pointer;
    }

    > div:nth-child(1) {
      height: 100%;
      width: 13px;
      border-radius: 7px 0 0 7px;
      background-color: var(--category-red);
    }

    // > span:last-child {
    //   margin-left: auto;
    //   margin-right: 0;
    // }

    > a {
      &:hover {
        color: var(--link-color);
      }
    }
  }

  .popup {
    max-height: 350px;
  }

  &__buttons {
    width: 100%;
    box-sizing: border-box;
    padding: 10px 15px 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    gap: 15px;
    border-top: 1px solid var(--table-border);

    .button {
      min-height: 35px !important;
      font-weight: 500;
    }
  }

  &__check-all {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px 15px 15px 10px;
    border-bottom: 1px solid var(--table-border);

    > p {
      font-size: 14px;
      font-weight: 600;
      color: var(--font-color);
    }

    > div {
      width: 25px;
      height: 25px;
      border-radius: 5px;
      border: 1px solid var(--checkbox-border);

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__active {
    background-color: var(--check-box-background);
    background-image: url('../../shared/image/svg/check-mark.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 13px;
  }
}

.seacrh-category-selected-item {
  &__button {
    width: 100%;
    border-radius: 7px;
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    height: 35px;
    padding: 0 30px 0 0px;
    align-content: center;
    background-color: var(--category-selected-item-background);

    display: flex;
    flex-direction: row;
    justify-content: start;
    gap: 10px;
    align-items: center;
    color: var(--font-color);

    &:hover {
      cursor: pointer;
    }

    > div:nth-child(1) {
      height: 100%;
      width: 13px;
      border-radius: 7px 0 0 7px;
      background-color: var(--category-red);
    }
  }

  &_isDisable {
    position: relative;
    &::after {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 7px;
      background-color: var(--category-disable-section);
      //opacity: 0.2;
      z-index: 3;
    }
  }
  .react-checkbox-tree {
    padding-left: 20px;
  }
}

.filter-category-selected {
  // &__check-all {
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: space-between;
  //   align-items: center;
  //   padding: 15px 15px 15px 10px;
  //   border-bottom: 1px solid var(--table-border);

  //   > p {
  //     font-size: 14px;
  //     font-weight: 600;
  //     color: var(--font-color);
  //   }

  //   > div {
  //     width: 25px;
  //     height: 25px;
  //     border-radius: 5px;
  //     border: 1px solid var(--checkbox-border);

  //     &:hover {
  //       cursor: pointer;
  //     }
  //   }
  // }

  // &__active {
  //   background-color: var(--check-box-background);
  //   background-image: url('../../shared/image/svg/check-mark.svg');
  //   background-position: center;
  //   background-repeat: no-repeat;
  //   background-size: 13px;
  // }
}

.react-checkbox-tree {
  overflow-y: auto;
  padding-right: 7px;

  // scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 3px;
    height: 7px;

    //background-color: red;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: var(--table-scroll-color);
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
}

.rct-node {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  font-size: 13px;
  color: var(--font-color);

  > ol {
    width: 100%;
  }
}

.rct-icon-expand-open {
  background-image: var(--arrow-bottom);
  background-position: right;
  // background-position-x: calc(100% - 12px);
  background-repeat: no-repeat;
  transform: rotate(180deg);
  width: 14px;
  height: 14px;

  &::before {
    content: '' !important;
  }
}

.rct-icon-expand-close {
  background-image: var(--arrow-bottom);

  background-position: right;
  background-repeat: no-repeat;
  &::before {
    content: none !important;
  }
  width: 14px;
  height: 14px;
}
.rct-text {
  width: 100%;
  min-height: 45px;
  border-bottom: 1px solid var(--table-border);
  position: relative;
  .rct-collapse {
    position: absolute;
    right: 35px;
    top: 15px;
  }

  label {
    order: 1;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-left: 5px;

    .rct-title {
      order: 1;
    }

    .rct-checkbox {
      order: 3;
    }

    .rct-node-icon {
      display: none;
    }
  }

  .rct-icon-uncheck,
  .rct-icon-half-check {
    width: 25px;
    height: 25px;
    border-radius: 5px;
    border: 1px solid var(--checkbox-border);

    &::before {
      content: '' !important;
    }
  }

  .rct-icon-check {
    width: 25px;
    height: 25px;
    border-radius: 5px;
    border: 1px solid var(--checkbox-border);
    background-color: var(--check-box-background);
    // background-color: var(--);
    background-image: url('../../shared/image/svg/check-mark.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 13px;

    &::before {
      content: '' !important;
    }
  }
}
