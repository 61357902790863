.sidebar {
  background-color: var(--aside-background);
  box-shadow: -1px 4px 38px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 274px;
  z-index: 10;

  transition: 200ms;
  max-width: 75px;

  #nav-icon {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 15px;
    top: 15px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    display: none;
  }

  #nav-icon span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: var(--aside-font-color-hover);
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }

  #nav-icon span:nth-child(1) {
    top: 0px;
  }

  #nav-icon span:nth-child(2),
  #nav-icon span:nth-child(3) {
    top: 10px;
  }

  #nav-icon span:nth-child(4) {
    top: 20px;
  }

  #nav-icon.open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
  }

  #nav-icon.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  #nav-icon.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  #nav-icon.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }

  &:hover {
    max-width: 230px;
    transform: translate(0);
  }

  &:hover &__toggle-icon {
    display: block;
  }
  &:hover .sidebarItem__subcategories {
    display: flex;
  }

  &:hover .sidebarItem {
    border-top: none;
  }
  &:hover .sidebarItem__title {
    display: block;
  }

  &:hover .sidebarItem__arrow {
    display: block;
  }

  &:hover &__logo > div:nth-child(2) {
    display: block;
  }

  &__logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    padding: 5px 0;

    // &:hover{
    //     cursor: pointer;
    // }
  }

  &__logo > div:first-child {
    margin-left: 20px;

    > svg {
      width: 35px;
    }
  }

  &__logo > div:nth-child(2) {
    line-height: normal;
    letter-spacing: 3.125px;
    text-transform: uppercase;
    color: var(--aside-font-color-hover);
    margin-left: 9px;
    display: none;
    transition: 200ms;
    font-family: Roboto;
  }

  &__toggle {
    height: 17px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-direction: row;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    z-index: 5;
    .toggle__slider {
      background-color: var(--theme-toogle-back-color);
    }
  }

  &__toggle-icon {
    display: none;
  }

  &__toggle-icon > svg {
    width: 17px;
    height: 17px;
  }
  &__toggle-icon > svg > * {
    stroke: var(--aside-toogle-icon);
  }
}

@media screen and (max-width: 840px) {
  .sidebar {
    position: sticky;
    top: 0;
    width: 100% !important;
    max-width: 100% !important;
    height: 54px !important;
    min-height: 54px;
    overflow-y: hidden;

    
    &__logo {
      width: fit-content;
    }

    &__toggle-icon {
      display: block;
    }

    .sidebarItem{
      border-top: none;
    }

    .sidebarItem__title {
      display: flex;
    }

    .sidebarItem__arrow {
      display: block;
    }

    .sidebarItem__subcategories {
      display: flex;
    }

    &.open {
      min-height: 100%;
      position: fixed;
      top: 0;
      bottom: 0;
      z-index: 1001;
    }

    #top-menu-btn {
      margin-left: auto;
      margin-right: 15px;
    }

    #nav-icon {
      display: block;
    }

    &__logo > div:nth-child(2) {
      display: block !important;
    }
  }
}
