#root {
  scrollbar-width: none;
  scroll-behavior: smooth;
}

//Мобильный в вертикальном повороте
@media screen and (max-width: 840px) {
  #root {
    // background-color: red;
  }
  .modal {
    // top: 35% !important;
    // left: 5% !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: 80% !important;

    &__header {
      .button {
        width: 20px !important;
        max-width: 20px !important;
      }
    }
  }

  .start-page {
    > * {
      padding: 0 10% !important;
    }

    &__buttons {
      flex-direction: column !important;
    }

    &__top-menu {
      flex-direction: column !important;
    }

    &__header {
      #nav-icon {
        width: 25px;
        height: 25px;
        position: relative;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.5s ease-in-out;
        -moz-transition: 0.5s ease-in-out;
        -o-transition: 0.5s ease-in-out;
        transition: 0.5s ease-in-out;
        cursor: pointer;
      }

      #nav-icon span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: var(--white_1);
        border-radius: 9px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;
      }

      #nav-icon span:nth-child(1) {
        top: 0px;
      }

      #nav-icon span:nth-child(2),
      #nav-icon span:nth-child(3) {
        top: 10px;
      }

      #nav-icon span:nth-child(4) {
        top: 20px;
      }

      #nav-icon.open span:nth-child(1) {
        top: 18px;
        width: 0%;
        left: 50%;
      }

      #nav-icon.open span:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      #nav-icon.open span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }

      #nav-icon.open span:nth-child(4) {
        top: 18px;
        width: 0%;
        left: 50%;
      }
    }

    &__mune-container {
      display: none !important;
      &_active {
        display: flex !important;
        position: fixed;
        top: 0;
        left: 0;
        flex-direction: column !important;
        justify-content: space-around !important;
        align-items: center !important;
        width: 100vw !important;
        height: 100vh !important;
        background-color: var(--dark_blue);
        padding: 0 !important;
        gap: 50px;
        // z-index: 11 !important;
      }
    }

    &__footer {
      > div {
        flex-direction: column !important;
        gap: 20px !important;
      }

      > div:nth-child(2) {
        > div:nth-child(1) {
          order: 2 !important;
          > a {
            text-align: center;
          }
        }
        > div:nth-child(2) {
          order: 1 !important;
        }
      }

      > div:last-child {
        > div {
          order: 1 !important;
        }
        > span {
          order: 2 !important;
        }
      }
    }
  }

  .main-section {
    height: 100vh !important;
    flex-direction: column !important;

    .button {
      position: relative !important;
      z-index: 1 !important;
    }

    > svg {
      top: -79px !important;
      width: 120% !important;
    }

    > div {
      margin-top: 50px !important;
      align-items: center !important;
      width: 100% !important;

      > h1 {
        width: 100%;
        font-size: 20px;
        text-align: center;
      }

      > div {
        margin-top: 280px;
        > p {
          text-align: center;
        }
      }
    }
  }
  .advantages-section {
    margin-bottom: 100px !important;
    height: auto !important;
    > div {
      grid-template-columns: 100% !important;
      > div {
        min-width: 100% !important;
        min-height: 140px !important;

        >p{
          text-align: start !important;
        }
      }

      > div:nth-child(4) {
        grid-row-start: 6 !important;
        grid-row-end: 6 !important;
      }
    }
  }
  .description-section {
    flex-direction: column !important;
    border-radius: 0 !important;
    padding: 20px 10% !important;

    > div {
      width: 100% !important;

      &:last-child{
        p{
          text-align: center !important;
        }
      }
    }

    > div:first-child {
      padding-right: 0 !important;
      border-right: none !important;
      padding-bottom: 40px !important;

      border-image: linear-gradient(
          to right,

          rgba(0, 0, 0, 0),
          #25ba7b,
          rgba(0, 0, 0, 0)
        )
        1 5% !important;
      border-top: none !important;

      p {
         text-align: left !important;
      }
    }
    
  }

  .opportunities-section {
    height: auto !important;
    margin-bottom: 40px !important;
    > div {
      flex-direction: column !important;
      > div {
        width: 100% !important;

        &:last-child {
          > div:nth-child(1) {
            order: 2 !important;
          }
          > div:nth-child(2) {
            order: 1 !important;
          }
        }
      }
    }

    &__image {
      min-height: 185px !important;
      // background-image: ;
    }

    &__info {
      max-height: 300px !important;

      >p:not( :first-child){
        text-align: start !important;
      }
    }
  }

  .fastReg-section {
    margin: 20px 0 !important;
    background-image: none !important;
    // height: 100vh !important;
    // background-position: -10% 140% !important;
    // background-size:200% !important;
    h2 {
      text-align: center;
    }
    > .button {
      width: 100% !important;
    }

    > div {
      flex-direction: column !important;
      width: 100% !important;

      > div {
        width: 100% !important;
      }
    }

    // display: none !important;
  }

  .feedback-section {

    &__info>p{
      text-align: start !important;
    }
    > div {
      flex-direction: column !important;
      > div {
        width: 100% !important;
      }
    }

    &__form > div {
      grid-template-columns: 100% !important;
    }

    .button {
      width: 100% !important;
    }

    &__input {
      > textarea {
        min-height: 130px !important;
      }
    }
  }

  .tariffs-section {
    margin-bottom: 100px;
    > div {
      > div {
        width: 100% !important;
        max-width: 100% !important;

        > p:nth-child(1) {
          font-size: 16px !important;
        }
        > p:nth-child(2) {
          font-size: 22px !important;
        }

        > div > p {
          font-size: 13px !important;
        }
      }
    }
  }

  .solutions-section {
    height: 100vh !important;
    > div {
      flex-direction: column !important;
      margin-top: 20px !important;
      gap: 20px !important;

      > div {
        width: 100% !important;
      }

      > div:first-child {
        > div {
          width: 100% !important;
          > svg {
            margin-left: auto;
            margin-right: 0;
          }
        }
      }
      >div:last-child{
        padding-right: 35px !important;
        padding-left: 35px !important;
        >p{
          margin-bottom: 25px !important;
          padding-right: 40px !important;
        }
      }
    }

    div > div:last-child > div{
      p{
        text-align: start !important;
      }
    }
  }
}

.start-page {
  box-sizing: border-box;
  padding: 50px 0px 150px 0px;
  margin: 0;
  width: 100%;
  // height: 100%;
  background-color: var(--dark_blue);
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  position: relative;
  font-family: 'Manrope';
  scroll-behavior: smooth;

  > * {
    padding: 0 14%;
  }

  &__header {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2;
  }

  &__logo {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 10px;

    > svg > *:nth-child(1),
    > svg > *:nth-child(2),
    > svg > *:nth-child(3) {
      fill: var(--green);
    }
    > svg > *:nth-child(4),
    > svg > *:nth-child(5),
    > svg > *:nth-child(6) {
      fill: var(--white_2);
    }

    > span {
      color: var(--green);
      font-size: 28px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      line-height: 33px;
      letter-spacing: 0.125em;
      text-transform: uppercase;
    }
  }

  &__mune-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-right: 0;
    padding-left: 50px;
    margin-left: auto;
  }

  &__top-menu {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 50px;

    > div {
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      gap: 5px;

      > a {
        font-size: 18px;
        color: var(--white_1);

        &:hover{
          cursor: pointer;
        }
      }

      > svg > * {
        stroke: var(--white_1);
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 10px;

    > .button {
      color: var(--white_1) !important;
    }
  }

  &__footer {
    margin-top: auto;
    width: 100%;

    > div:nth-child(1) {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      > div:nth-child(2) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        gap: 50px;

        > a {
          font-size: 18px;
          color: var(--white_1);
        }
      }

      > div:nth-child(3) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        gap: 8px;

        > a {
          width: 62px;
          height: 62px;
          border-radius: 100%;
          background: rgba(37, 186, 123, 0.2);
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
      }
    }

    > div:nth-child(2) {
      margin-top: 45px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      > div {
        display: flex;
        flex-direction: column;
        justify-content: start;
        gap: 8px;

        > a {
          margin: 0;
          font-family: 'Manrope';
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          color: #c8c8c8;
          transition: all 0.3s;
        }

        > a:hover {
          color: var(--green);
        }
      }
    }

    > div:nth-child(3) {
      margin-top: 55px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      > span {
        margin: 0;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        color: #c8c8c8;
      }

      > div {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        gap: 16px;

        > .button {
          color: var(--white_1) !important;
        }

        > a:nth-child(1) {
          width: auto;
          height: 41px;
          padding: 0 15px;

          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          border: 1px solid var(--white_1);
          border-radius: 5px;

          font-family: 'Manrope';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 130.5%;
          color: var(--white_1);
        }

        > a:nth-child(2) {
          width: auto;
          padding: 0 15px;
          height: 41px;
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          background-color: var(--green);

          font-family: 'Manrope';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 130.5%;
          color: var(--white_1);
        }
      }
    }
  }

  &__green {
    color: var(--green) !important;
  }

  h1 {
    text-transform: uppercase;
    color: var(--white_1);
    font-size: 28px;
  }

  &__section-8 {
    margin-top: 100px;
    width: 100%;
    height: 500px;

    h1 {
      margin-bottom: 40px;
    }

    > div {
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: start;
      gap: 50px;

      > div:nth-child(1) {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        gap: 35px;
      }
    }
  }

  &__info-block {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 20px;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      gap: 10px;
    }

    > div > p:first-child {
      font-weight: 500;
      font-size: 14px;
      line-height: 130.5%;
      color: #595566;
    }

    > div > p:nth-child(2) {
      font-size: 14px;
      line-height: 130.5%;
      color: var(--white_1);
    }
  }
}

.main-section {
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;

  h1 {
    width: 45%;
  }

  > div {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 20px;
  }

  > svg {
    top: -55px;
    right: 0;
    position: absolute;
    width: 65%;
    // height: 60%;
    z-index: 1;
  }
  div > p {
    font-size: 16px;
    color: var(--white_1);
    width: 350px;
    line-height: 27px;
    font-weight: 600;
  }

  .button {
    min-height: 50px;
    height: 60px;
    width: 220px;
    margin-top: 40px;
  }
}
.description-section {
  box-sizing: border-box;
  background: #191e33;
  backdrop-filter: blur(5px);
  border-radius: 211px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  width: auto;
  padding: 70px 14%;
  gap: 40px;
  margin-top: 50px;
  margin-bottom: 50px;

  > div:nth-child(1) {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 20px;
    border-width: 1px;
    border-style: solid;

    padding-right: 40px;
    border-image: linear-gradient(
        to bottom,

        rgba(0, 0, 0, 0),
        #25ba7b,
        rgba(0, 0, 0, 0)
      )
      1 100%;
    border-left: none;

    > div {
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      gap: 10px;

      > p {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 130.5%;
        color: #c8c8c8;
      }

      > svg > * {
        fill: var(--green);
      }
    }
  }

  > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 50%;
    gap: 30px;

    > p {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 130.5%;
      color: #c8c8c8;
      text-align: justify;

      > span {
        color: var(--green);
      }
    }

    > svg {
      width: 100%;
    }
  }
}

.fastReg-section {
  margin: 50px 0;
  background-image: url('../../shared/image/svg/main-fast-reg-back.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 375px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 30px;

  > h2 {
    font-size: 20px;
    color: var(--white_1);
    font-weight: 500;
  }

  > div {
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }

  > .button {
    width: 30%;
    font-weight: 500;
  }
}

.solutions-section {
  width: 100%;
  height: 520px;
  background-image: url('../../shared/image/svg/main-3-section.svg');
  background-position: left;
  background-repeat: no-repeat;
  background-size: auto;

  > h1 {
    margin-top: 90px;
  }

  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    // flex-wrap: wrap;
    height: max-content;

    > div:first-child {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      gap: 15px;
      width: 50%;

      > div {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        gap: 10px;
        color: var(--grey);
        font-size: 18px;

        > svg > * {
          stroke: var(--grey);
        }

        &:hover {
          cursor: pointer;
          color: var(--white_1);
          > svg > * {
            stroke: var(--white_1);
          }
        }
      }
      > div.active {
        color: var(--white_1);

        > svg > * {
          stroke: var(--white_1);
        }
      }
    }

    > div:last-child {
      width: 50%;
      height: 100%;
      background-color: var(--dark-input);
      border-radius: 10px;
      border: 1px solid rgba(37, 186, 123, 0.5);
      position: relative;
      box-sizing: border-box;
      padding: 30px 45px;
      padding-right: 120px;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      gap: 15px;

      > span {
        position: absolute;
        top: 0;
        right: 30px;
        color: var(--dark_blue);
        font-size: 70px;
        font-weight: bold;
      }

      > p {
        font-size: 16px;
        line-height: 130.5%;
        color: var(--white_1);
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
        margin-bottom: 5px;
      }

      > div {
        font-weight: 400;
        font-size: 14px;
        line-height: 130.5%;
        display: -webkit-inline-box;
        color: var(--grey);
        font-weight: 400;
        line-height: 130.5%;

        > p {
          flex: none;
          order: 1;
          flex-grow: 0;
          text-align: justify;
          text-align-last: 5px;
          margin-left: 5px;
        }
      }
    }
  }
}
.opportunities-section {
  width: 100%;
  height: 800px;

  > div {
    margin-top: 40px;
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;

    > div {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      gap: 20px;

      > div {
        width: 100%;
        border-radius: 10px;
      }

      &:first-child > .opportunities-section__image {
        background-image: url('../../shared/image/svg/main-section-4-1.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }

      &:last-child > .opportunities-section__image {
        background-image: url('../../shared/image/svg/main-section-4-2.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }

  &__image {
    height: 40%;
    border: 1px solid var(--grey);
  }

  &__p_title {
    color: var(--white_1) !important;
    font-size: 14px !important;
    margin: 10px 0;
    height: fit-content !important;
  }

  &__info {
    height: 60%;
    border: 1px solid rgba(37, 186, 123, 0.5);
    box-sizing: border-box;
    padding: 15px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 1px;
      width: 1px;
      border: 2px solid var(--dark-input);
      background-color: var(--grey);
    }
    &::-webkit-scrollbar-thumb {
      background: var(--green);
      border-radius: 4px;
      background-clip: content-box;
    }

    > p:first-child {
      font-size: 16px;
      color: var(--white_1);
      position: relative;

      margin: 0;
      padding-bottom: 5px;
      width: fit-content;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border-bottom: 1px solid;
        border-image: linear-gradient(to right, rgba(0, 0, 0, 0), #25ba7b, rgba(0, 0, 0, 0)) 47% 0%;
      }
    }

    > p:not(:first-child) {
      color: var(--grey);
      font-size: 12px;
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;
      text-align: justify;
    }
  }
}
.advantages-section {
  width: 100%;
  height: 450px;

  h1 {
    margin-bottom: 75px;
  }

  > div {
    display: grid;
    grid-template-columns: repeat(3, 2fr);
    grid-template-rows: repeat(2, 1fr);
    width: 100%;
    height: 70%;
    gap: 20px;

    > div {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      position: relative;

      &:not(:nth-child(3), :nth-child(4))::before {
        content: '';
        width: calc(100% + 1px);
        height: calc(100% + 1px);
        position: absolute;
        // z-index: -1;

        border-radius: 10px;
        border: 1px solid transparent;
        background: linear-gradient(45deg, transparent, var(--green)) border-box;
        -webkit-mask:
          linear-gradient(#fff 0 0) padding-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
      }

      &:not(:nth-child(3), :nth-child(4)) {
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        gap: 10px;
        > p:first-child {
          font-size: 16px;
          color: var(--white_1);
          margin-left: 30px;
          margin-right: 30px;
          font-weight: 600;
        }

        > p:nth-child(2) {
          font-size: 12px;
          color: var(--white_1);
          margin-left: 30px;
          margin-right: 30px;
          text-align: justify;
          font-weight: 400;
        }

        > span {
          position: absolute;
          left: 0;
          bottom: -20px;
          font-size: 48px;
          color: var(--white_1);
          font-weight: 700;
        }
      }

      &:nth-child(3) {
        background-image: url('../../shared/image/svg/main-section-5-2.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        border: none;
        -webkit-mask: none !important;
      }

      &:nth-child(4) {
        background-image: url('../../shared/image/svg/main-section-5-1.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        border: none;
        -webkit-mask: none !important;
      }
    }
  }
}
.tariffs-section {
  margin-top: 100px;
  width: 100%;
  // height: 1000px;

  > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    margin-top: 40px;
    transition: 0.3s;

    > div:hover {
      .button {
        background-color: var(--white_1);
        color: var(--black);
      }

      background-color: rgba(37, 186, 123, 0.5);

      > div > p > svg > * {
        fill: var(--white_1);
      }
    }

    > div {
      flex-grow: 1;
      width: 30%;
      max-width: 32%;
      height: 100%;
      aspect-ratio: 3 / 4;
      border-radius: 10px;
      border: 1px solid var(--green);
      background-color: var(--dark-input);
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      box-sizing: border-box;
      gap: 30px;
      padding: 40px 30px 0 30px;

      > p {
        color: var(--white_1);
        width: 100%;
        text-align: center;
      }

      > .button {
        width: 100%;
        font-weight: 500;
      }

      > div {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        gap: 10px;
        > p {
          color: var(--white_1);
          font-size: 12px;

          > svg {
            margin-right: 10px;
            > * {
              fill: var(--green);
            }
          }
        }
      }
    }
  }
}
.reviews-section {
  margin-top: 100px;
  width: 100%;
  height: 500px;

  h1 {
    margin-bottom: 40px;
  }

  > div:last-child {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-top: 20px;
  }

  .button {
    border-radius: 50%;
    width: 45px;
    height: 45px;
  }
}
.feedback-section {
  width: 100%;
  height: auto;
  margin-bottom: 180px;

  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;
    gap: 70px;

    > div {
      &:nth-child(1) {
        width: 40%;
      }
      &:nth-child(2) {
        width: 60%;
        height: auto;
      }

      height: 100%;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 15px;

    > p:nth-child(1) {
      color: var(--white_1);
      font-size: 18px;
    }

    > p:nth-child(2) {
      color: var(--grey);
      font-size: 16px;
      text-align: justify;
    }
  }

  &__contacts {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 30px;
  }

  &__contact-item {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 20px;

    > svg {
      height: 55px;
      width: 55px;
    }

    > div {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      gap: 5px;

      > p {
        color: var(--grey);
        font-size: 14px;
      }

      > a,
      > p:last-child {
        color: var(--white_1);
        font-size: 16px;
      }
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 20px;
    height: 100%;
    > div:nth-child(1) {
      display: grid;
      grid-template-columns: repeat(2, calc(50% - 10px));

      gap: 20px;
      width: 100%;
    }

    > div:nth-child(2) {
      flex-grow: 1;
      width: 100%;
      height: auto;
      textarea {
        height: 100%;
        width: 100%;
        border: 1px solid rgba(37, 186, 123, 0.2);
        border-radius: 5px;
        background-color: var(--dark-input);
        text-align: start;
        color: var(--white_1);
        font-size: 14px;
        padding: 5px 15px;
        &::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }
        &::-webkit-scrollbar-track {
          border-radius: 1px;
          width: 1px;
          border: 2px solid var(--dark-input);
          background-color: var(--grey);
        }
        &::-webkit-scrollbar-thumb {
          background: var(--green);
          border-radius: 4px;
          background-clip: content-box;
        }
      }
    }
  }
  &__input {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 15px;
    width: 100%;

    > p {
      font-size: 14px;
      color: var(--white_1);
      font-weight: 300;
    }

    .select {
      &__button {
        height: 50px;
        background-color: var(--dark-input);
        border: 1px solid rgba(37, 186, 123, 0.2);
        color: var(--white_1);
        background-image: url('../../shared/image/svg/arrow-bottom-white.svg');
        overflow: hidden;
        width: 100%;
      }

      .popup {
        background-color: var(--dark-input);
        padding: 0;
      }

      .radio-group {
        > div {
          color: var(--white_1);
          border-bottom: 1px solid rgba(37, 186, 123, 0.2);

          &:last-child {
            border-bottom: none;
          }
        }

        svg > * {
          fill: var(--white_1);
        }
      }
    }
  }

  &__modal {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 15px;
    min-height: 230px;

    > p {
      color: var(--white_1);

      &:nth-child(2) {
        font-size: 20px;
      }

      &:nth-child(3) {
        font-size: 16px;
      }
    }

    .button {
      min-width: 130px;
    }
  }

  .button {
    font-weight: 500 !important;
  }
}

@media (min-width: 1920px) {
  .start-page {
    > * {
      padding: 0 26%;
    }
  }

  .description-section {
    padding: 70px 26%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .solutions-section {
    height: auto;
    > div {
      margin-top: 30px;
      flex-direction: column;
      gap: 30px;
      > div:first-child {
        width: 100%;
      }
      > div:last-child {
        width: 100%;
      }
    }
  }

  .tariffs-section {
    margin-top: 100px;
    width: 100%;

    > div {
      > div {
        height: 400px;
      }
    }
  }
}
