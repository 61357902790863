.error-developmant-page {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;

  > svg {
    width: 170px;
    height: 170px;
  }

  > p {
    font-size: 30px;
    text-align: center;
  }
}
