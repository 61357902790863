.toggle {
  height: 17px;
  position: relative;
  z-index: 5;

  &__check {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 17px;
    flex-shrink: 0;
  }

  &__slider {
    border-radius: 20px;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: solid 1px var(--dark_blue);
    background-color: var(--white_1);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  &__slider:before {
    position: absolute;
    content: '';
    height: 11px;
    width: 11px;
    left: 4px;
    bottom: 2px;
    background-color: var(--dark_blue);
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 20px;
  }

  &__input:checked + &__slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
  }
  &__input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}
