.review-slide {
  height: 250px;
  width: 100%;
  border: 1px solid var(--green);
  border-radius: 10px;
  background-color: var(--dark-input);
  box-sizing: border-box;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 15px;
  position: relative;

  &__star {
    > svg {
      width: 20px;
      height: 20px;
    }
    > svg > * {
      stroke: rgba(37, 186, 123, 0.3);
      fill: rgba(37, 186, 123, 0.3);
    }

    &_isFill {
      svg > * {
        fill: var(--green);
        stroke: var(--green);
      }
    }
  }

  &__stars {
    display: flex;
    flex-direction: row;
    grid-area: 5px;
  }

  &__text {
    color: var(--revie-text);
    font-size: 12px;
    text-align: justify;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__all-text {
    color: var(--revie-text);
    font-size: 12px;
    text-align: justify;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  &__author {
    font-size: 14px;
    color: var(--white_2);
    margin-top: auto;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: auto;
  }

  a {
    font-size: 14px;
    color: var(--green);
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }
}
