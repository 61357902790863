.info-table {
  border: 1px solid var(--table-border);
  border-collapse: collapse;
  

  th,
  td {
    border: 1px solid var(--table-border);
    font-size: 12px;
    box-sizing: border-box;
    padding: 3px 20px 3px 20px;
    height: 35px;
    max-width: 280px;
  }

  > thead {
    th {
      text-align: start;
      color: var(--grey);

      &:first-child {
        width: fit-content;
      }
    }
  }

  > tbody {
    width: 100%;
    height: auto;

    > tr {
      width: 100%;
      height: fit-content;

      > td {
        color: var(--table-text);
      }
    }

    a {
      color: var(--link-color);
    }

    div {
      aspect-ratio: 5 / 2;
    }
  }

  $colors: (#e75fc9, #566dad, #50c5b0, #ebb254, #5e439e, #ca4545, #cdcfd0);
  $colorsLength: length($colors);

  @for $i from 1 through $colorsLength {
    tbody > tr:nth-child(#{$i}) {
      div {
        background-color: nth($colors, $i);
      }
    }
  }
}
