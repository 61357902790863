.button {
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: none;
  font-weight: 600;
  line-height: 30px;
  width: auto;
  min-height: 45px !important;
  font-size: 14px;
  padding: 0 20px;

  color: var(--white);
  gap: 15px;

  > svg {
    width: 20px;
    height: 20px;
    overflow: visible;
  }

  &_size {
    &_big {
      height: 45xp;
    }

    &_small {
      width: 35px;
      height: 35px;
      padding: 0;
    }
  }

  &_color {
    &_transition {
      background-color: transparent;
      border: 1px solid var(--white_1);
      color: var(--font-color) !important;
    }

    &_green {
      background-color: var(--green);
      color: var(--white_1);

      &.button_disable {
        // background-color: rgba(37, 186, 123, 0.6);
        background-color: rgba(37, 186, 123, 0.1);

        // border: 1px solid var(--white_1);
      }
    }

    &_violet {
      background-color: var(--blue-btn-back);
      color: var(--white_1);
      font-weight: 400;

      &.button_disable {
        background-color: var(--blue-btn-back-disable);
      }
    }

    &_white {
      background-color: var(--white);
      color: var(--button-icon-text);
      border: 1px solid var(--input-border);
    }
  }

  &_disable {
    &:hover {
      cursor: auto;
    }
  }
}
