.barChart {
  display: flex;
  width: inherit;
  height: inherit;
  //background-color: pink;
  // zoom: 1.31;

  align-items: center;
  justify-content: center;

  > p {
    font-size: 14px;
    color: var(--font-color);

    margin-top: 80px;
  }



  &__error {
    margin-top: auto;
    margin-bottom: auto;
    color: var(--font-color);

  }
}
