.promotion-card-page {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding: 7px 50px;
  background-color: var(--background);

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 50px;
    margin-top: 30px;
    margin-bottom: 20px;

    .calendar {
      margin-left: auto;
      margin-right: 0;
    }

    > .button {
      border: none;
    }
  }

  &__error {
    width: 100%;
    height: calc(100vh - 85px);
    display: flex;
    flex-direction: column;
    gap: 40px;
    justify-content: center;
    align-items: center;

    > div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 20px;

      > p {
        color: var(--font-color);
        font-size: 20px;
        text-transform: uppercase;
      }
    }

    > p {
      color: var(--font-color);
      font-size: 18px;
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 15px;
  }

  &__chart-stat-row {
    width: 100%;
    height: 100%;
    border: 1px solid var(--table-border);
    border-radius: 10px;
    padding: 25px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    grid-column-start: 1;
    grid-column-end: 4;

    > div {
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      gap: 20px;

      > p {
        font-size: 14px;
        color:var(--font-color);
        &:last-child {
          color: var(--green);
        }
      }
    }
  }

  &__chart {
    width: 100%;
    border: 1px solid var(--table-border);
    border-radius: 10px;
    padding: 25px;
    margin-top: 15px;
    min-height: 380px;
    display: flex;
    > .small-loader {
      margin: auto;
    }

    .check-group__check-item > span {
      width: max-content !important;
    }
  }

  &__calendar-container {
    margin-left: auto;
    &_isShow {
      position: relative;
      &:hover::after {
        content: attr(data-title);
        position: absolute;
        right: calc(100% + 10px);
        top: -15px;
        z-index: 1;
        width: 270px;
        padding: 10px;
        height: 44px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: justify;
        align-items: center;
        background-color: var(--popup-background);
        border-radius: 3px;
        box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
        font-size: 14px;
        color: var(--grey);
      }
    }
  }

  &__help {
    position: relative;

    &:hover::after {
      content: attr(data-title);
      position: absolute;
      right: calc(100% + 10px);
      top: -15px;
      z-index: 1;
      width: 270px;
      padding: 10px;
      height: 44px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      text-align: justify;
      align-items: center;
      background-color: var(--popup-background);
      border-radius: 3px;
      box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
      font-size: 14px;
      color: var(--grey);
    }

    .button {
      border-radius: 0px 5px 5px 0;
      min-height: 35px !important;
      max-height: 35px !important;
      height: 35px;
      font-weight: 500 !important;
      border: none;
      > svg {
        transition: stroke 1s ease-in-out;
        width: 30px;
        height: 30px;
      }
    }

    &_isRed {
      .button {
        > svg {
          transition: stroke 1s ease-in-out;
          stroke: red;
        }
      }
    }
  }
}

@media screen and (max-width: 840px) {
  .promotion-card-page {
    padding: 7px 15px;

    &__header {
      margin-top: 0;
      margin-bottom: 20px;
      flex-wrap: wrap;
      height: auto;
      

      > .button {
        display: none;
      }
    }

    &__calendar-container {
      &_isShow {
        position: relative;
        &:hover::after {
          content: attr(data-title);
          position: absolute;
          left: -60px;
          top: -15px;
          z-index: 1;
          width: 270px;
          padding: 10px;
          height: 44px;
        }
      }
    }

    &__chart-stat-row {
      grid-column-start: 1;
      grid-column-end: 1;
      flex-direction: column;
      padding: 0;

      > div {
        border-top: 1px solid var(--table-border);
        padding: 10px;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        > p {
          text-align: center;
        }

        &:first-child {
          border-top: none;
        }
      }
    }
  }
}
