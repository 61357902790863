.range-inputs {
  width: 100%;
  height: auto;
  span {
    font-size: 14px;
    color: var(--font-color);
  }

  > div {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    width: 100%;
    gap: 5px;
    margin-top: 10px;
  }
  input {
    box-sizing: border-box;
    border: 1px solid var(--input-border);
    border-radius: 5px;
    background-color: transparent;
    height: 100%;
    width: 100%;
    height: 35px;
    text-align: center;
    font-weight: 500;
    color: var(--font-color);

  }
}
