.small-loader {
    width: 120px;
    height: 30px;
    display: flex;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 5px !important;

    >span {


        width: 10px;
        height: 10px;

        background-color: var(--light_gray_1);
        border-radius: 50%;
        animation: loader 1.2s infinite normal; 
    }

    >span:nth-of-type(2) {
        animation-delay: 0.2s;
    }

    >span:nth-of-type(3) {
        animation-delay: 0.4s;
    }

    >span:nth-of-type(4) {
        animation-delay: 0.6s;
    }

    >span:nth-of-type(5) {
        animation-delay: 0.8s;
    }

    >span:nth-of-type(6) {
        animation-delay: 1s;
    }

    @keyframes loader {
        0% {
            background-color: #272264;

        }

        100% {
            background-color: #D9D9D9;


        }
    }

}