.checkbox {
  width: 20px;
  height: 20px;
  border: 1px solid var(--checkbox-border);
  border-radius: 5px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  svg {
    width: 13px;
  }

  svg > * {
    fill: var(--white_1);
  }

  &:hover {
    cursor: pointer;
  }

  &_isActive {
    background-color: var(--check-box-background);
  }
}
