.big-loader{
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 1000;
  

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--white);

    &_color_dark {
      background: var(--dark);
      opacity: 0.7;
    }
  }

  &__inner {
    animation: load 4s infinite;
  }

  &__left {
    animation: load-left 4s infinite;
    transform: translateX(-1.25rem);
  }

  &__right {
    animation: load-right 4s infinite;
    transform: translateX(1.25rem);
  }
}

@keyframes load {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5) rotate(-180deg);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes load-left {
  50% {
    transform: translateX(0);
  }
}

@keyframes load-right {
  50% {
    transform: translateX(0);
  }
}
