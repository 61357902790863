.tooltip {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 5px;
  font-size: 12px;

  background-color: var(--tooltip-background);
  box-sizing: border-box;
  border-radius: 3px;
  padding: 3px 5px;
  color: var(--tooltip-font-color);
  &__label {
    color: var(--grey);
  }
}
