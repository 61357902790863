.radio-group {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  box-sizing: border-box;
  width: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 3px;
    height: 7px;

    //background-color: red;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: var(--table-scroll-color);
  }
  &::-webkit-scrollbar-button {
    display: none;
  }

  > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: var(--font-color);
    font-size: 14px;
    border-bottom: 1px solid var(--table-border);
    min-height: 45px;
    padding: 0 10px;

    > svg > * {
      fill: var(--icon-color);
    }

    &:hover {
      cursor: pointer;
    }
  }
}
