.expander {
    width: 100%;

    &__title {
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        cursor: pointer;


        >svg {
            width: 20px;
            height: 20px;
            margin-left: 10px;
            transition: 0.6s;
        }
    }

    &__wrapper {
        display: grid;
        grid-template-rows: 0fr;
        transition: grid-template-rows 0.6s ease-in;
    }

    &__visible {
        overflow: visible;
    }
    &__invisible {
        overflow: hidden;
    }

    &__wrapper-active {
        grid-template-rows: 1fr;
    }

    &__title-active {
        >svg {
            rotate: 180deg;
        }
    }
}