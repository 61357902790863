.export {
  position: relative;
  width: auto;
  height: auto;

  .popup {
    width: 100%;
    gap: 15px;
    padding-bottom: 10px;
    justify-content: center;
    align-items: center;
   
    .button {
      min-height: 30px !important;
      max-height: 30px !important;
      height: 30px;
      font-weight: 500 !important;
      width: 90%;
    }
  }
}
