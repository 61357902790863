.products-page {
  //background-color: greenyellow;

  > div:first-child {
    margin-right: 0;
    margin-left: auto;
    margin-bottom: 10px;
    margin-top: -65px;
    width: fit-content;
    position: relative;

    &:hover::after {
      content: attr(data-title);
      position: absolute;
      right: calc(100% + 10px);
      top: 0;
      z-index: 1;
      width: 340px;
      padding: 0 5px;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background-color: var(--popup-background);
      border-radius: 3px;
      box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
      font-size: 14px;
      color: var(--grey);
    }
  }

  &__charts {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px;
    align-items: center;
    margin-bottom: 30px;

    > .stat-card {
      width: 22%;
      flex-grow: 1;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 15px;
    margin: 30px 0 10px 0;
    flex-wrap: wrap;

    > .pagination {
      margin-left: auto;
      margin-right: 0;
    }
  }
}


@media screen and (max-width: 840px) {
  .products-page{
    > div:first-child{
      display: none;
    }
  }
}