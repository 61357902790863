.stat-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15px;
    width: fit-content;
    min-width: 260px;
    //max-width: 420px;
    box-sizing: border-box;
    padding: 25px 20px;
    border-radius: 10px;
    min-height: 116px;

    >svg{
        height: 55px;
        overflow: visible;
    }

    >div:nth-child(2) {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        gap: 0px;

        >p{
            margin: 0;
        }
        >p:nth-child(1) {
            font-weight: 700;
            font-size: 17px;
            line-height: 20px;
            display: flex;
            color: var(--grey);
        }

        >p:nth-child(2) {
            font-weight: 700;
            font-size: 18px;
            //line-height: 33px;
            color: var(--stat-card-digit-color);
        }
    }

    &_type {
        &_grey {
            background-color: var(--stat-card--type-grey-background);
        }

        &_transporate{
            background-color: transparent;
        }
    }
}