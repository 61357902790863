.warehouse-table {
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    >div{
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      gap: 10px;
    }

    .check-group {
      flex-direction: row;
      color: var(--font-color);
     
    }
  }
  &__help {
    position: relative;

    &:hover::after {
      content: attr(data-title);
      position: absolute;
      left: calc(100% + 10px);
      top: -15px;
      z-index: 1;
      width: 280px;
      padding: 10px;
      height: 44px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      text-align: justify;
      align-items: center;
      background-color: var(--popup-background);
      border-radius: 3px;
      box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
      font-size: 14px;
      color: var(--grey);
    }

    .button {
      border-radius: 0px 5px 5px 0;
      min-height: 35px !important;
      max-height: 35px !important;
      height: 35px;
      font-weight: 500 !important;
      border: none;
      > svg {

        width: 30px;
        height: 30px;
        stroke: red;
      }
    }

  }

  &__check-warehouse {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 15px;
    margin-top: 20px;
    flex-wrap: wrap;

    > p {
      font-size: 16px;
      font-weight: 700;
      color: var(--font-color);

      &:hover {
        cursor: pointer;
      }
    }

    > p.disable {
      text-decoration: line-through;
      color: var(--grey);
    }
  }

  &__table {
    margin-top: 20px;
    overflow-x: auto;
    transform: rotateX(180deg);
    //position: relative;

    &::-webkit-scrollbar {
      width: 3px;
      height: 6px;
      
      //background-color: red;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: var(--table-scroll-color);
      &:hover{
        cursor: pointer;
      }
    }
    &::-webkit-scrollbar-button {
      display: none;
    }

    > div {
      transform: rotateX(180deg);
      //position: relative;
    }

    table {
      //   width: 100%;
      min-width: -webkit-fill-available;
      border-collapse: collapse !important;
      border-spacing: 0px;
      margin-bottom: 5px;
      table-layout: fixed;
      tbody {
        // display: table;
        width: 100%;
      }
      th {
        background-color: var(--header-background-color);
        min-height: 55px;
        // height: 55px;
        min-width: 130px;
        font-weight: 500;
        font-size: 14px;
        color: var(--header-table);
        box-sizing: border-box;
        padding: 0 15px;

        border-right: 1px solid var(--table-border);
        border-bottom: 1px solid var(--table-border);
        color: var(--font-color);

        position: relative;
      }

      th:last-child {
        border-right: none;
      }
      thead {
        min-height: 67px;

        tr:nth-child(2) th {
          border-top: 1px solid var(--table-border);
        }
      }

      tr {
        min-height: 67px;
      }

      td {
        width: inherit;
        text-align: center;
        vertical-align: middle;
        font-size: 14px;
        padding: 15px 10px;
        border-bottom: 1px solid var(--table-border);
        border-left: 1px solid var(--table-border);
        border-right: 1px solid var(--table-border);
        // max-height: 35px;
        height: 55px;
        color: var(--font-color);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      //   td:nth-child(6n + 4),
      //   td:nth-child(6n + 3),
      //   td:nth-child(6n + 2) {
      //     background: rgba(37, 186, 123, 0.09);
      //   }

      //   thead {
      //     > tr:nth-child(1) {
      //       th:nth-child(2n + 6),
      //       th:nth-child(4),
      //       th:nth-child(3),
      //       th:nth-child(2) {
      //         background-color: rgb(226, 240, 234);
      //       }
      //     }

      //     > tr:nth-child(2) {
      //       th:nth-child(6n + 4),
      //       th:nth-child(6n + 5),
      //       th:nth-child(6n + 6) {
      //         background-color: rgb(226, 240, 234);
      //       }
      //     }
      //   }
    }
  }

  &__double-h {
    height: 40px;

    &_isGreen {
      background-color: var(--warehouse-table-colorful-item) !important;
    }
  }

  &__th-background {
    background-color: var(--warehouse-table-colorful-item) !important;
  }
}


@media screen and (max-width: 840px) {
  .warehouse-table{
    &__header{
      flex-direction: column;
      gap: 15px;
    }
  }
}