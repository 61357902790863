.campanies-filter {

  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 10px;
  &__selected {
    color: var(--font-color);
    > span {
      font-size: 14px;
      color: var(--font-color);
    }

    > .select {
      margin-top: 10px;

      .search-input {
        margin-bottom: 10px;
      }
    }
  }

  &__isActivePanel {
    // margin: 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 14px;

      color: var(--font-color);
    }

    > div {
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      gap: 5px;
    }
  }
}
