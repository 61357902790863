.login-page {
  box-sizing: border-box;
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: var(--dark_blue);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('../../shared/image/svg/background-login.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &__quickReg{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 15px;

    >p{
      color: var(--white_1);
    }
  }


  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 17%;
    gap: 15px;
    > h1 {
      font-weight: 700;
      font-size: 20px;
      line-height: 130.5%;
      color: var(--white_1);
      flex: none;
      order: 0;
      flex-grow: 0;
      margin: 0 0 15px 0;
    }

    a {
      color: var(--green);
      font-size: 12px;
      align-self: flex-end;
      text-decoration: underline;
    }

    span {
      color: var(--white_2);
      font-size: 12px;
    }

    > button {
      width: 100%;
    }
  }

  &__error {
    color: var(--red) !important;
  }

  .modal {
    width: 30% !important;
    height: 40% !important;
    // left: 35% !important;
  }

  &__double-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 20px;
    margin-top: -20px;
  }

  &__success-reg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 20px;
    margin-top: -20px;
  }
}

@media screen and (max-width: 992px) {
  .login-page > div {
    width: 40%;
  }
}

@media screen and (max-width: 768px) {
  .login-page > div {
    width: 55%;
  }
}

@media screen and (max-width: 480px) {
  .login-page > div {
    width: 75%;
  }
}
