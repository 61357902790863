@import './reset';
@import './root';

@import './text';

$tableScrollSize: 0.8rem;

// html {
//   height: 100%;

//   @media (max-width: 1680px) and (max-resolution: 100dpi) {
//     font-size: 14px;
//   }

//   @media (max-width: 1600px) and (max-resolution: 100dpi) {
//     font-size: 13.5px;
//   }

//   @media (max-width: 1500px) {
//     font-size: 13.5px;
//   }

//   @media (max-width: 1440px) {
//     font-size: 12px;
//   }

//   @media (max-width: 1366px) {
//     font-size: 11.5px;
//   }

//   @media (max-width: 1280px) {
//     font-size: 10px;
//   }
// }



input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
  transition: background-color 5000s ease-in-out 0s;
}

body {
  color: var(--text_default_color);
  font-size: 14px;
  font-weight: 500;
  font-family: var(--font_family_default);
  height: 100%;
  overflow-y: hidden;
}

input,
textarea {
  font-family: var(--font_family_default);
}

#root {
  height: 100%;
  overflow-y: auto;



  &::-webkit-scrollbar {
    width: $tableScrollSize;
    height: $tableScrollSize;
    border-radius: $tableScrollSize;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--aside_background_hover);
    border-radius: $tableScrollSize;
  }
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: var(--background);

  font-family: Manrope;
  color: var(--black);
  font-style: normal;

  input:focus {
    outline: none;
  }
}



// @media screen and (min-resolution: 120dpi) {
//   body {
//     font-size: 1.56rem;
//   }
// }



.container {
  padding: 0 3.625rem;
}

.d-flex {
  display: flex;
}

.font-weight-700 {
  font-weight: 700 !important;
}

fieldset.invalid {
  border-color: var(--red, red) !important;
}
