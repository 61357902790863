.subcategory-page {
  padding: 7px 50px;
  background-color: var(--background);

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;

  &__checkbox {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 5px;
    // margin-top: 15px;

    > span {
      font-size: 14px;
      color: var(--font-color);
    }
  }

  &__products-pagination {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    >div:first-child{
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      gap: 10px;
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 50px;
    margin-top: 30px;
    margin-bottom: 20px;

    > .button {
      border: none;
    }
  }

  &__name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    color: var(--font-color);
    // &:hover::after {
    //   content: attr(data-title);
    //   position: absolute;
    //   right: 0;
    //   top: 0;
    //   z-index: 1;
    //   width: 340px;
    //   padding: 0 5px;
    //   height: fit-content;
    //   display: flex;
    //   flex-direction: row;
    //   justify-content: center;
    //   align-items: center;
    //   background-color: #f5f5f5;
    //   border-radius: 3px;
    //   box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
    //   font-size: 14px;
    //   color: #7b7983;
    // }
  }

  &__title {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__stats-cards {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &__stat-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 10px;
    margin: 0 10px;

    > p:nth-child(1) {
      font-size: 18px;
      font-weight: 700;
      text-align: start;
      color: var(--font-color);
    }

    > p:nth-child(2) {
      font-size: 16px;
      font-weight: 600;
      color: var(--grey);
      text-align: start;
      text-overflow: ellipsis;
    }
  }

  &__charts {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 20px;

    // > .stat-card {
    //   width: 15%;
    //   flex-grow: 1;
    //   max-height: 120px;
    //   min-height: 120px;
    // }

    > .stat-card {
      flex-grow: 1;
      padding: 25px 15px;
      max-height: 120px;
      min-height: 120px;
      width: fit-content;
      > div {
        width: fit-content;
      }
    }
  }

  &__line-chart {
    width: 100%;
    border: 1px solid var(--table-border);
    border-radius: 10px;
    padding: 25px;
    margin-top: 50px;
    min-height: 380px;
    display: flex;
    > .small-loader {
      margin: auto;
    }
  }
}

@media screen and (max-width: 840px) {
  .subcategory-page {
    padding: 7px 15px;

    &__header {
      margin-top: 0;
      margin-bottom: 0;

      > .button {
        display: none;
      }
    }

    &__stats-cards {
      grid-template-columns: repeat(2, 1fr);
      border: 1px solid var(--table-border);
      border-radius: 10px;
      padding: 0;
      gap: 0;
    }

    &__stat-item {
      align-items: center;
      border: 1px solid var(--table-border);
      margin: 0;
      padding: 10px;

      >p:last-child{
        text-align: center;
      }

      &:nth-child(1) {
        border-top: none;
        border-left: none;
        border-right: none;
      }

      &:nth-child(2) {
        border-top: none;
        border-right: none;
      }
      &:nth-child(3) {
        border-top: none;
        border-left: none;
        border-right: none;
      }

      &:nth-child(4) {
        border-top: none;
        border-right: none;
      }

      &:nth-child(5) {
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: none;
      }
      &:nth-child(6) {
        border-top: none;
        border-right: none;
        border-bottom: none;
      }
    }

    &__title{
      flex-direction: column;

      .calendar{
        margin-left: auto;
        margin-right: 0;
        margin-top: 10px;
      }
    }

    &__line-chart{
      margin-top: 20px;
    }
  }
}
