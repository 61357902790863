.input {
  width: 100%;
  > input {
    box-sizing: border-box;
    border: 1px solid var(--input-border);
    border-radius: 5px;
    padding: 5px 15px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    height: 35px;
    width: 100%;
    margin-top: 10px;
  }

  > input:-webkit-autofill {
    color: var(--font-color) !important;
    -webkit-text-fill-color: var(--black) !important;
  }

  > input:focus {
    outline: none;
    //border: 2px solid var(--green);
  }

  > input:hover {
    cursor: pointer;
  }

  &_type {
    &_normal {
      > input {
        font-size: 14px;
        font-weight: 500;
        //color: var(--dark-grey);
        background-color: transparent;
        color: var(--font-color);
      }
    }

    &_password {
      position: relative;

      > .button {
        position: absolute;
        right: 15px;
        bottom: 9px;
        width: 20px !important;
        height: 20px !important;
        min-height: 20px !important;
        color: var(--font-color);


        svg > * {
          fill: var(--black);
        }
      }
    }

    &_dark {
      width: 100%;
      margin: 0;

      position: relative;

      > .button {
        position: absolute;
        right: 15px;
        top: 17px;
        width: 20px !important;
        height: 20px !important;
        min-height: 20px !important;
        border: none;

        svg > * {
          fill: var(--green);
        }
      }
      > input {
        margin: 0;
        border: 1px solid rgba(37, 186, 123, 0.2);
        border-radius: 5px;
        background-color: var(--dark-input);
        text-align: start;
        color: var(--white_1);
        font-size: 14px;
        width: 100%;
        height: 50px;
        position: relative;
      }

      > input:-webkit-autofill {
        color: var(--white_1) !important;
        -webkit-text-fill-color: var(--white_1) !important;
      }

      .input__error {
        position: absolute;
        top: -17px;
        padding-right: 20px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
        background-image: url('../../shared//image/svg/question-red.svg');
        background-repeat: no-repeat;
        background-position: right;

        &:hover {
          cursor: pointer;

          overflow: visible;
          color: transparent;
        }

        &:hover::after {
          content: attr(data-title);
          position: absolute;
          left: 0;
          top: 0px;
          z-index: 1;
          max-width: 560px;
          padding: 5px;
          height: 35px;
          line-height: 20px;
          // text-wrap:wrap;
          display: flex;
          flex-direction: row;
          justify-content: start;
          text-align: start;
          align-items: center;
          background-color: var(--dark-input);
          border-radius: 3px;
          box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
          font-size: 12px;
          color: red;
          // flex-wrap: wrap;
          box-sizing: border-box;
        }
      }
    }
  }

  &_badValidate {
    input {
      border-color: var(--red) !important;
    }
  }

  &__error {
    color: var(--red);
    font-size: 12px;
    margin: 0;
    margin-bottom: -10px;
    text-align: justify;
  }

  &_icon {
    position: relative;

    > input {
      padding-left: 40px;
    }

    > svg {
      position: absolute;
      top: 7px;
      left: 10px;
      width: 25px;
      height: 25px;
    }
  }
}
