.quick-login {
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  gap: 15px;

  > .button {
    min-width: 40px !important;
    min-height: 40px !important;
    max-width: 40px !important;
    max-height: 40px !important;
    width: 40px !important;
    height: 40px !important;
  }
  > div {
    width: 100%;
  }
}
