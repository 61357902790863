.bar-panel {
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  transition: 200ms;
  min-width: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  max-width: 0;
  // z-index: 5;
  z-index: 1000;

  background: var(--aside-background);
  box-shadow: 0px 4px 63px rgba(0, 0, 0, 0.25);

  &_isShow {
    min-width: 310px;
    max-width: 310px;
    transform: translate(0);
  }

  &__header {
    height: 55px;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--side-panel-header);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    > p {
      font-size: 16px;
      font-weight: 700;
      color: var(--font-color);
    }

    > .button {
      border: none;
    }
  }

  > div:last-child {
    height: calc(100% - 55px);
  }
}
