.check-group {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 10px;

  &::-webkit-scrollbar {
    width: 3px;
    height: 7px;

    //background-color: red;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: var(--table-scroll-color);
  }
  &::-webkit-scrollbar-button {
    display: none;
  }

  &_markPosition_left {
    > div {
      justify-content: start;
      gap: 10px;

      > span {
        order: 2;
      }
      > div {
        order: 1;
        border-color: var(--checkbox-border);
      }
    }
  }

  &_markPosition_right {
    justify-content: space-between;

    > div {
      border-color: var(--white_2);
    }
  }

  &__check-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    &:hover {
      cursor: pointer;
    }

    > span {
      font-size: 14px;
      font-weight: 500;
    }

    > div {
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;
      border: 1px solid var(--white_2);
      border-radius: 5px;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      svg {
        width: 13px;
      }

      svg > * {
        fill: var(--white_1);
      }
    }
  }

  &__active {
    background-color: var(--check-box-background);
  }
}
