.modal {
  width: 580px;
  height: fit-content;
  min-height: 200px;
  border: none;
  border-radius: 20px;
  // top: calc(50% - 100px);
  // left: calc(50% - 290px);
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 0 !important;

  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;

  &_color_light {
    background-color: var(--modal-backgroung);
    border: var(--modal-border);
  }

  &_color_dark {
    background-color: var(--dark-input);
    border: 1px solid var(--green);

    p {
      color: var(--white_2);
    }

    .modal__header {
      border-bottom: none;
      background-color: transparent;
      svg > * {
        stroke: var(--green) !important;
      }
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--table-border);
    background-color: var(--modal-backgroung);

    p {
      font-size: 16px;
      margin-left: 15px;
      color: var(--font-color);
    }

    .button {
      margin-left: auto;
      margin-right: 5px;
      border: none;

      svg > * {
        // stroke: var(--black);
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    height: 100%;
    padding: 15px;
    > div {
      width: 100%;
    }

    p {
      font-size: 14px;
    }
  }
}
