.favorite-radio-group {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;

  > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    color: var(--black);
    font-size: 14px;
    border-bottom: 1px solid var(--table-border);
    min-height: 45px;
    padding: 0 10px;
    gap: 10px;
    color:var(--font-color);

    > div:first-child {
      width: 20px;
      height: 20px;
      border-radius: 5px;
      border: 1px solid var(--table-border);
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    svg {
      width: 15px;
      height: 13px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__red {
    background-color: #ef0b0b;
  }

  &__green {
    background-color: #25ba7b;
  }

  &__blue {
    background-color: #1d75bd;
  }
}
