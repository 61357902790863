.sellers-page {
  > div:first-child {
    margin-right: 0;
    margin-left: auto;
    margin-bottom: 10px;
    margin-top: -65px;
    width: fit-content;
    position: relative;

    &:hover::after {
      content: attr(data-title);
      position: absolute;
      right: calc(100% + 10px);
      top: 0;
      z-index: 1;
      width: 340px;
      padding: 0 5px;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background-color: var(--popup-background);
      border-radius: 3px;
      box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
      font-size: 14px;
      color: var(--grey);
    }
  }
  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 15px;
    margin: 30px 0 0 0;

    > .pagination {
      margin-left: auto;
      margin-right: 0;
    }
  }

  &__title-panel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  > div:nth-child(2) {
    display: flex;
    flex-direction: row;
    justify-content: start;
    gap: 70px;
    align-items: center;
    flex-wrap: wrap;

    div > .info-table {
      align-self: self-start;
    }

    > .pie-chart {
      width: 30%;
    }
  }
}

@media screen and (max-width: 840px) {
  .sellers-page {
    > div:first-child {
      display: none;
    }

    > div:nth-child(2) {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: start;
      gap: 70px;
      align-items: center;
      flex-wrap: wrap;

      > div:nth-child(1) {
        width: 100%;
        overflow-x: auto;
        padding-bottom: 5px;

        > .info-table {
          width: 100%;
        }

        &::-webkit-scrollbar {
          width: 5px;
          height: 7px;

          //background-color: red;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 5px;
          background-color: var(--grey);
        }
        &::-webkit-scrollbar-button {
          display: none;
        }
      }

      > .pie-chart {
        width: 100%;
      }
    }

    &__title-panel {
      gap: 20px;
      flex-direction: column;
      justify-content: start;
      align-items: start;

      > .search-input {
        width: 100%;
      }
    }

    &__buttons {
      margin: 20px 0 0 0;
      flex-wrap: wrap;
    }
  }
}
