.search-input {
  box-sizing: border-box;
  border: 1px solid var(--input-border);
  border-radius: 5px;
  padding: 5px 15px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 5px;
  flex-wrap: nowrap;
  height: 35px;

  > svg {
    width: 24px;
    height: 21px;
  }

  > input {
    width: 100%;
    height: 100%;
    font-size: 14px;
    color: var(--search-input-font-color);
    background-color: var(--transporate);
  }
}
